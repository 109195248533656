// CSS RESET
// ----------------------------------------------------

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6,
p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small,
strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table,
caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption,
footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote {
  &:before, &:after {
    content: '';
    content: none;
  }
}

q {
  &:before, &:after {
    content: '';
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  font-size: 100%;
}

th {
  text-align: center;
}

tbody th {
  text-align: left;
}

// Accessible focus treatment: people.opera.com/patrickl/experiments/keyboard/test
a:hover,
a:active {
  outline: 0;
}

input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

// HTML5 SHIZZLEZ
header,
footer,
section,
nav,
aside,
article,
figure,
figcaption {
  display: block;
}

* {
  box-sizing: border-box
}

html {
  height: 100%;
  -webkit-text-size-adjust: 100%;
}

body,
form,
ol,
ul,
li,
dl,
dt,
dd,
td,
th,
tr,
fieldset {
  margin: 0;
  padding: 0;
}


h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
blockquote,
address {
  margin: 0 0 1em 0;
  padding: 0;
}
ul {
  list-style: none;
}
ol {
  margin-bottom: 1em;
}
ol > li {
  margin-left: 2em;
}
img,
fieldset {
  border: 0;
  -ms-interpolation-mode: bicubic;
}
