.button {
  display: inline-block;
  -webkit-appearance: none;
  appearance: none;
  padding: 0.8em 1.1em;
  margin: 0 0.3em 0 0;
  border: 3px solid;
  border-radius: 99px;
  background: none;
  color: #2164db;
  font-weight: 600;
  font-size: 1.1em;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  cursor: pointer;
  vertical-align: middle;

  [disabled] {
    cursor: default;
  }
}
.button:not([disabled]):hover,
.button:focus,
.button:active {
  background: #2164db;
  border-color: transparent;
  color: #fff;
}
.button.success {
  color: $color-success;
}
.button.success:not([disabled]):hover,
.button.success:focus,
.button.success:active {
  background: $color-success;
  color: #fff;
}
.button.destructive {
  color: #d24444;
}
.button.destructive:not([disabled]):hover,
.button.destructive:focus,
.button.destructive:active {
  background: #d24444;
  color: #fff;
}
.button.dim {
  color: rgba(0,0,0,0.3);

  &.active {
    background: rgba(0,0,0,0.3);
    color: #fff;
  }
}
.button.dim:not([disabled]):hover,
.button.dim:focus,
.button.dim:active {
  background: rgba(0,0,0,0.3);
  color: #fff;
}
.button.block {
  display: block;
}
.button.small {
  border-width: 2px;
  font-size: 1em;
  padding: 0.6em 0.8em;
}
.button.disabled {
  color: #777777;
  border-color: #777777;
}
.button-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
}
.button-group .button:not(:only-child) {
  border-radius: 0;
  margin: 0;
}
.button-group .button:not(:only-child):first-child {
  border-radius: 99px 0 0 99px;
  border-right: 0;
}
.button-group .button:not(:only-child):last-child {
  border-radius: 0 99px 99px 0;
}
.dropdown-list-rightside {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}
.button-dropdown {
  position: relative;
}
.button-dropdown .dropdown-list {
  display: none;
  font-size: .8em;
  z-index: 1;
  background-color: white;
  border: 2px solid #2164db;
  border-radius: 15px;
  text-align: left;
}

.button-dropdown .dropdown-list li {
  border-bottom: 1px solid #2164db;
  color: #2164db;
  white-space: nowrap;
  a {
    display: block;
    padding: .8em .8em .8em 1.1em;
  }
}

.button-dropdown .dropdown-list li.destructive {
  color: #d24444;
}

.button-dropdown .dropdown-list li.destructive:hover, .button-dropdown .dropdown-list li a.destructive:hover {
  background-color: #d24444;
}

.button-dropdown .dropdown-list li:hover, .button-dropdown .dropdown-list li a:hover {
  background-color: #2164db;
  color: white;
}

.button-dropdown .dropdown-list li:first-child {
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;

  a:hover {
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
  }
}

.button-dropdown .dropdown-list li:last-child {
  border-bottom: none;
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
  a:hover {
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
  }
}

.button-dropdown:hover .dropdown-list,
.button-dropdown.active .dropdown-list {
  display: block;
  position: absolute;
  top: 3em;
  left: 0;
}
.button-leftside-dropdown {
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  margin-right: 0px;
}
.dropdown-list-rightside {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-left: 0px;
  margin-left: -5px;
  padding: 0.71em;
}
