$color-primary:     #005cdb;
$color-esthios:     #dd019a;
$color-error:       #d24444;
$color-error-light: #f58787;
$color-success:     #69d321;
$color-info:        #2164db;
$color-warning:     #fbb03b;
$color-warning-light: #f7c26f;
$color-destructive: $color-error;
$color-danger:      $color-error;
$color-link:        $color-info;

$color-calendar:   #ffab0f;
$color-customers:  #69d321;
$color-register:   #8262d8;
$color-products:   #a47433;
$color-punchclock: #f8549c;
$color-reports:    #d24444;
$color-marketing:  #24a7f3;
$color-website:    #f8549c;
$color-loyaltycards: #00ddff;

$colors: (
  primary: $color-primary,
  error: $color-error,
        error-light: $color-error-light,
        warning-light: $color-warning-light,
  success: $color-success,
  info: $color-info,
  warning: $color-warning,
  destructive: $color-destructive,
  danger: $color-danger,

  calendar: $color-calendar,
  customers: $color-customers,
  register: $color-register,
  products: $color-products,
  punchclock: $color-punchclock,
  reports: $color-reports,
  marketing: $color-marketing,
  website: $color-website,
  loyaltycards: $color-loyaltycards,
  white: #ffffff
);

$sizes: (
    small: .5em,
    medium: 1em,
    large: 2em,
    none: 0,
);

$directions: (
  'top',
  'right',
  'bottom',
  'left'
);
