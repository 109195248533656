.lockscreen {
  z-index: 400;
  opacity: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.lockscreen-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.5);
  opacity: 1;
  transition: opacity 0.2s ease-out;
}
@media (max-width: 767px) {
  .lockscreen-backdrop {
    display: none;
  }
}
.lockscreen-content {
  position: absolute;
  top: 50%;
  left: 50%;
  background: #fff;
  box-shadow: 0 0.8em 1.5em rgba(0,0,0,0.2);
  transition: transform 0.2s ease-out;
  max-height: 100%;
  overflow: auto;
  transform: translate(-50%, -50%);
  padding: 1em;
}
