.dialog-wrapper {
  z-index: -2;
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 5em;
  right: 0;
}
.module-home .dialog-wrapper {
  left: 0;
}
@media (max-width: 1023px) {
  .dialog-wrapper {
    left: 3.75em;
  }
}
@media (max-width: 767px) {
  .dialog-wrapper {
    left: 0;
  }
}
.dialog-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.5);
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
}
@media (max-width: 767px) {
  .dialog-backdrop {
    display: none;
  }
}
.dialog {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -100%);
  -moz-transform: translate(-50%, -100%);
  -ms-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
  background: #fff;
  min-width: 30em;
  -webkit-box-shadow: 0 0.8em 1.5em rgba(0,0,0,0.2);
  box-shadow: 0 0.8em 1.5em rgba(0,0,0,0.2);
  -webkit-transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  max-height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  
  &.locked .dialog-close {
    display: none;
  }

  .dialog-close-button {
    position: absolute;
    top: 0.8em;
    right: 0.7em;
    font-size: 1.4em;
  }
}
 
@media (max-width: 767px) {
  .dialog {
    min-width: 0;
    width: 100%;
  }
}
.dialog-size-large {
  width: 70em;
}
@media (max-width: 1199px) {
  .dialog-size-large {
    width: 100%;
  }
}
.toolbar,
.main-content {
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
@media (max-width: 767px) {
  .has-dialog {
    .intercom-lightweight-app {
      display: none;
    }
  }
}
.has-dialog .dialog-wrapper {
  z-index: 100;
  opacity: 1;
}
.open-dialog .toolbar,
.open-dialog .main-content {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
@media (max-width: 767px) {
  .open-dialog .toolbar,
  .open-dialog .main-content {
    -webkit-filter: none;
    filter: none;
  }
}
.open-dialog .dialog-backdrop {
  opacity: 1;
}
.open-dialog .dialog {
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.dialog-content {
  padding: 1em 1.5em 1.5em;
}
@media (max-width: 767px) {
  .dialog-content {
    padding-left: 1em;
    padding-right: 1em;
  }
}
.dialog h1 {
  text-align: center;
  font-size: 1.3em;
  font-weight: 400;
  margin: 0 0 1.2em;
  padding: 0 1.5em;
  line-height: 1.2;
}
.dialog-actions {
  text-align: right;
}
.dialog-actions .button {
  margin: 0 0 0 0.3em;
}
.dialog-3col,
.dialog-2col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.dialog-3col .col {
  width: 31%;
  margin: 0 0 1em;
}
.dialog-3col .col2 {
  width: 62%;
  margin: 0 0 1em;
}
.dialog-2col .col {
  width: 48%;
  margin: 0 0 1em;
}
.dialog-3col .col.dialog-actions,
.dialog-2col .col.dialog-actions {
  align-self: flex-end;
  margin-bottom: 0;
  width: 100%;
}
@media (max-width: 1023px) {
  .dialog-3col .col,
  .dialog-3col .col2,
  .dialog-3col .col.dialog-actions {
    width: 48%;
  }
}
@media (max-width: 767px) {
  .dialog-3col,
  .dialog-2col {
    display: block;
    width: auto;
  }
  .dialog-3col .col,
  .dialog-2col .col,
  .dialog-3col .col2,
  .dialog-3col .col.dialog-actions,
  .dialog-2col .col.dialog-actions{
    width: auto;
    margin-bottom: 1.5em;
  }
}
