.card {
  display: block;
  position: relative;
  padding: 2.5em 1.5em;
  min-height: 3.3em;
  text-align: left;
  background-color: #ececec;
  border-radius: 20px;

  .title {
    font-size: 1.2em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: .4em;
    font-weight: 300;
  }

  .description-title {
    font-weight: bold;
  }

  .description,
  .description-title,
  .extra,
  .future-dates,
  .label {
    font-size: .8em;
    margin-bottom: .4em;
    margin-top: .4em;
  }

  .debug-info {
    padding: 0 0 0 1em;
  }

  .extra,
  .label {
    color: rgba(0, 0, 0, 0.4);
  }

  .actions {
    text-align: center;
  }

  .button {
    margin: .4em;
    padding: .2em .8em;
    border-width: 2px;
  }

  &.free-moment-time-slot {
    width: 48%;
    margin-right: 0.5em;
    .actions {
      margin-top: -2em;
      text-align: right;
    }
  }
}

@each $name, $color in $colors {
  .card-#{$name} {
    box-shadow: none;
    border: 1px solid $color;
  }
}

.card-with-image {
  padding-left: 3.4em;
  min-height: 4.4em;

  .image {
    position: absolute;
    top: 0.5em;
    left: 0.5em;
    width: 2.4em;

    img, i {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }

    i {
      font-size: 2.3em;
    }
  }
}

.card-selectable {
  margin-left: 2em;
  cursor: pointer;

  .select-container {
    position: absolute;
    left: -2em;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.card-selected {
  background-color: rgba($color-info, .05);
  box-shadow: inset 0 0 0 1px rgba($color-info, 1);
}

.card-grid-2 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .card-wrapper {
    flex: 0 1 50%;
    flex-basis: 50%;

    .card {
      margin: .5em;
    }
  }
}
