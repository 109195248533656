fieldset {
  margin: 0 0 2em;
}

fieldset label {
  display: block;
  -webkit-box-shadow: inset 0 0 0 1px #ddd;
  box-shadow: inset 0 0 0 1px #ddd;
  margin: 0 0 -1px;
  padding: 1em 0 1em 1em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
}

fieldset label:first-child {
  border-radius: 5px 5px 0 0;
}

fieldset label:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

fieldset .text {
  -webkit-flex: 0 1 40%;
  -ms-flex: 0 1 40%;
  flex: 0 1 40%;
}

fieldset .field {
  -webkit-flex: 0 1 60%;
  -ms-flex: 0 1 60%;
  flex: 0 1 60%;
}

fieldset input.text,
fieldset .select {
  margin: -1em 0;
  background: none;
  border: 0;
  width: 100%;
}

fieldset input[type=checkbox] {
  margin: 0 0 0 1em;
}

fieldset select {
  border: 0;
  background: none;
  width: 100%;
}

fieldset .form-error {
  -webkit-flex-basis: 100%;
  -ms-flex-basis: 100%;
  flex-basis: 100%;
  position: absolute;
  left: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0 0 0 0.2em;
  max-width: 13em;
}

fieldset .form-error:before {
  bottom: auto;
  left: auto;
  top: 50%;
  right: 100%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border: 0;
  border-right: 0.5em solid #ee1a43;
  border-top: 0.5em solid transparent;
  border-bottom: 0.5em solid transparent;
}

@media (max-width: 1023px) {
  fieldset .form-error {
    top: -1em;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    transform: none;
    left: auto;
    right: 0;
    max-width: 100%;
  }
  fieldset .form-error:before {
    top: 100%;
    right: 0.8em;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    transform: none;
    border: 0;
    border-top: 0.5em solid #ee1a43;
    border-left: 0.5em solid transparent;
    border-right: 0.5em solid transparent;
  }
}

.form-error {
  display: inline-block;
  background: #ee1a43;
  color: #fff;
  padding: 0.5em 0.8em;
  font-size: 0.875em;
  border-radius: 5px;
  white-space: nowrap;
  position: relative;
}

.form-error:before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 0.8em;
  border-bottom: 0.5em solid #ee1a43;
  border-left: 0.5em solid transparent;
  border-right: 0.5em solid transparent;
}

.form-error:empty {
  display: none;
}

.form-progress div {
  padding-left: 2.5em;
}

.form-progress div:before,
.form-progress div:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 100%;
  border-left: 1em solid #f8f8f8;
  border-top: 1.7em solid transparent;
  border-bottom: 1.7em solid transparent;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.form-progress div:before {
  margin: 0 0 0 1px;
}

.form-progress :first-child > * {
  padding-left: 2em;
}

.form-progress :last-child > * {
  padding-right: 2em;
}

.form-progress :last-child > *:before,
.form-progress :last-child > *:after {
  display: none;
}

.form-progress .active:after,
.form-progress .done:after {
  border-left-color: #005cdb;
}

.form-progress .done {
  background: #428be3;
  color: rgba(255, 255, 255, 0.5);
}

@media (max-width: 599px) {
  .form-progress div {
    padding-left: 1.5em;
  }
  .form-progress :first-child > * {
    padding-left: 1em;
  }
  .form-progress :last-child > * {
    padding-right: 1em;
  }
}

input,
textarea,
button,
select {
  font: inherit;
  color: inherit;
  vertical-align: middle;
}

textarea {
  max-width: 100%;

  &.auto-expand {
    height: 1.6em;
    transition: height .3s ease;

    &:focus,
    .expanded {
      height: 7.2em;
    }
  }
}

select {
  -moz-appearance: none; /* remove FF dropdown arrow */
}

select::-ms-expand {
  display: none;
}

.input,
.select select {
  border: 0;
  margin: 0;
  padding: 0.2em 0;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  color: #232934;
  border-radius: 0;
  line-height: 1.4; /* sensible cross-browser default */
}

.select,
.select select {
  display: inline-block;
  background: none;
  -webkit-appearance: none;
  appearance: none;
}

.select {
  position: relative;
}

.select select {
  border: 0;
  margin: 0;
  padding-right: 1.3em;
  width: 100%;
}

.select:after {
  content: "\6b";
  font-family: "optios";
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  pointer-events: none;
}

.select-reverse select {
  padding-right: 0;
  padding-left: 1.3em;
}

.select-reverse:after {
  right: auto;
  left: 0;
}

.checkbox {
  -webkit-appearance: none;
  appearance: none;
  width: 1em;
  height: 1em;
  min-width: 1em;
  min-height: 1em;
  margin: 0 0.5em 0 0;
  font-size: 1.125em;
  background: none;
  color: transparent;
  vertical-align: -0.25em;
  border: 2px solid rgba(0, 0, 0, 0.3);
  cursor: pointer;
  position: relative;
  border-radius: 4px;
}

.checkbox:focus {
  border-color: #2164db;
}

.checkbox:checked {
  color: #fff;
  background: #2164db;
  border: none;
}

.checkbox:checked:before {
  content: "\6D";
  font-family: "optios";
  position: absolute;
  top: 0.05em;
  left: 0;
  pointer-events: none;
}

.form-split label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  cursor: pointer;
  margin: 0 0 0.7em;
  min-height: 1.6em;
  position: relative;
}

.form-split {
  .checkbox-row {
    flex-wrap: wrap;

    .help-block {
      width: 100%;
      margin-left: 2em;
    }
  }
}

.form-split label.full {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.form-split label.full .text,
.form-split label.full .input,
.form-split label.full .select {
  -webkit-flex-basis: 100%;
  -ms-flex-basis: 100%;
  flex-basis: 100%;
  overflow: visible;
}

.form-split label.checkbox-row .text {
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  overflow: visible;
}

.form-split .text,
.form-split .suffix,
.form-split .prefix {
  line-height: 1.4;
  color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.form-split .text {
  padding: 0.2em 0.5em 0.2em 0;
  -webkit-flex: 0 0 40%;
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
}

.form-split .suffix,
.form-split .prefix {
  padding: 0.2em 0 0.2em 0;
}

.form-split .input,
.form-split .select {
  -webkit-flex: 1 0 0;
  -ms-flex: 1 0 0;
  flex: 1 0 0;
  min-width: 0;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, .1);
  box-shadow: 0 1px 0 rgba(0, 0, 0, .1);
}

.form-split .tight {
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}

.form-split .input:focus,
.form-split select:focus {
  outline: none;
  -webkit-box-shadow: 0 1px 0 #2164db;
  box-shadow: 0 1px 0 #2164db;
}

.form-split textarea.input {
  width: 100%;
  margin: 0.5em 0 0;
}

.form-split .checkbox,
.form-split .radio {
  align-self: center;

  &[disabled] {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.form-split .input-prefix {
  margin: 0 0.3em 0 0;
  align-self: center;
}

.form-split .input.error:not(.status-message) {
  -webkit-box-shadow: 0 1px 0 #cb2829;
  box-shadow: 0 1px 0 #cb2829;
}

.form-split .input.valid {
  border-color: #69d321;
}

.form-split label.error:not(.status-message),
.form-split div.error:not(.status-message) {
  float: right;
  background: #cb2829;
  color: #fff;
  padding: 0.3em 0.4em;
  font-size: 0.9em;
  position: absolute;
  border-radius: 3px;
  right: 0;
  top: -1.4em;
}

.form-split label.error:not(.status-message):after,
.form-split div.error:not(.status-message):after {
  content: "";
  position: absolute;
  right: 0.4em;
  top: 100%;
  border-top: 0.5em solid #cb2829;
  border-left: 0.5em solid transparent;
}

.form-split .button {
  margin-top: 1.5em;
}

.form-split .help-block {
  font-size: .8em;
  margin-top: 0.4em;
}

.form-split td > label {
  margin: 0;
  padding: 0;
  min-height: auto;
  display: inline-block;
}

.form-split td > .checkbox-label {
  display: flex;
  white-space: nowrap;
  min-height: auto;
  max-width: 8vw;
  overflow: hidden;
}

.form-split td > label label.error:not(.status-message) {
  top: -2.4em;
}

@media (max-width: 1199px) {
  .form-split-tablet label {
    display: block;
    margin-bottom: 1em;
  }
  .form-split-tablet .text {
    width: auto;
    margin-bottom: 0.2em;
  }
  .form-split-tablet .input {
    width: 100%;
  }
}

.ui-slider .ui-slider-handle {
  border-radius: 20px;
  text-align: center;
  width: 2em;
  height: 2em;
  font-size: .75em;
  padding-top: .45em;
  margin-top: -.2em;
  background-color: #005cdb;
  border: none;
  color: #FFFFFF;
}

label + .ui-slider {
  margin-bottom: .7em;
}

.help-description {
  color: rgba(0, 0, 0, 0.5);

  .help-more-info {
    margin-top: 0.5em;
  }
}

.formgroup {
  border: 1px solid lightgray;
  padding: .5em;
  margin-bottom: .5em;
}

.form-grid-line {
  border-right: 1px solid grey;
}
