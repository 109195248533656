.calendar-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  .day {
    border-right: 2px solid rgba(0, 0, 0, 0.2);

    &:last-child {
      border: 0;
    }
  }

  .task-modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 72px;
    width: 350px;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    z-index: 50;

    .task-modal-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: baseline;
      margin: 1.5rem;

      .task-modal-content-title {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;

        p {
          margin: 0;
        }
      }

      .task-modal-close {
        align-self: flex-end;
        cursor: pointer;
        font-size: 1.25rem;
        font-weight: bold;
        color: #2164db;
      }

      .task-modal-close:hover {
        color: #2c5282;
      }
    }

    .task-modal-textarea {
      border: 1px solid #e2e8f0;
      border-radius: 0.375rem;
      padding: 0.75rem;
      resize: vertical;
      min-width: 60%;
      text-align: left;
    }

    .task-status-wrapper {
      align-items: center;
      display: flex;
      margin-bottom: 1rem;
    }

    .task-status-wrapper {
      align-items: center;
      display: flex;
      margin-bottom: 1rem;
    }

    .task-modal-separator-line {
      border: 1px solid #e2e8f0; /* Gray-300 */
      width: calc(100% + 3rem);
      margin-left: -1.5rem;
    }

    .task-modal-action-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: .8rem;

      .task-modal-action {
        background-color: #2164db;
        border: none;
        border-radius: 2rem;
        color: white;
        cursor: pointer;
        padding: .7rem;
        font-size: 16px;
        margin-right: 16px;
        text-align: center;
      }

      .task-modal-check-container {
        display: flex;
        margin-left: auto;

        .task-modal-check-text {
          margin: 0 16px 0 0;
          height: fit-content;
          align-self: center;
          font-size: 12px;
        }

        .task-modal-check {
          height: fit-content;
          align-self: center;
          font-size: 28px;
          color: #2164db;
        }

        .task-modal-check:hover {
          cursor: pointer;
          color: #2c5282;
        }
      }
    }

    .task-modal-save:hover {
      background-color: #2c5282;
    }
  }


  .modal-spinner-container {
    position: absolute;
    width: 100%;
    margin: -1.5rem 0 0 -1.5rem;
    background-color: rgba(255, 255, 255, 0.5);
    height: 100%;
    border-radius: 0.5rem;
  }

  .modal-spinner-container.idle {
    display: none;
  }


  .modal-back-drop {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 8;
  }
}

.calendar-header {
  position: absolute;
  min-width: 100%;
  background: rgb(215, 219, 225);
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding-left: 2em;
  text-align: center;

  .day {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;

    .label {
      padding: 0.6em 0 0.4em;
      text-transform: uppercase;
      font-size: 0.8em;
    }
  }

  .today {
    background: rgba(247, 164, 15, 0.3);
  }

  .cabine {
    background-color: #eab8ab;
  }

  .employees {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
  }

  .employee {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 0.6em 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 1em;
    display: flex;
    justify-content: center;
    align-items: center;

    .avatar {
      display: inline-block;
      width: 1.2em;
      height: 1.2em;
      border-radius: 99px;
      margin: -0.3em 0.5em -0.3em 0;
    }

    .task {
      cursor: pointer;
      width: 12px;
      height: 12px;
      border-radius: 10px;
      background-color: #9c9c9c;
      margin-left: 12px;
    }
  }
}

.employee.task-complete .task {
  background-color: #4caf50;
}

.employee.task-made .task {
  background-color: #2164db;
}

@media (max-width: 479px) {
  .calendar-header .employee {
    padding: 0.3em;
    display: flex;
    align-items: center;
    flex-direction: column;

    .avatar {
      width: 2em;
      height: 2em;
      margin: 0;
    }

    .name {
      display: none;
    }
  }
}

.calendar-span-3days .calendar-header .employee {
  font-size: 0.9em;
}

@media (max-width: 1199px) {
  .calendar-span-3days .calendar-header .employee {
    padding: 0.3em;
  }
  .calendar-span-3days .calendar-header .employee .avatar {
    width: 2em;
    height: 2em;
    margin: 0;
  }
  .calendar-span-3days .calendar-header .employee .name {
    display: none;
  }
}

.calendar-establishment-button {
  max-width: 400px;
}

@media (max-width: 1199px) {
  .calendar-establishment-button {
    max-width: 300px;
  }
}

@media (max-width: 1120px) {
  .calendar-establishment-button {
    max-width: 200px;
  }
}

.calendar-span-week .calendar-header .employee {
  font-size: 0.9em;
  padding: 0.3em;
}

.calendar-span-week .calendar-header .employee .avatar {
  width: 2em;
  height: 2em;
  margin: 0;
}

.calendar-span-week .calendar-header .employee .name {
  display: none;
}

.calendar-content {
  position: absolute;
  top: 2.2em;
  bottom: 0;
  min-width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 5;
  -webkit-overflow-scrolling: touch;

  // Fix for lay-out bug where scrollbar takes place, but customers complain scrollbar is hidden. Solutions?
  //&::-webkit-scrollbar{
  //  -webkit-appearance: none;
  //  appearance: none;
  //  width: 0;
  //  height: 0;
  //}
}

.calendar-message {
  color: red;
  position: absolute;
  width: 100%;
  text-align: center;
  padding-top: 8px;
}

.calendar-message.hidden {
  display: none;
}

@media (max-width: 479px) {
  .calendar-content {
    top: 2.7em;
  }
}

.calendar-span-3days .calendar-content {
  top: 3.55em;
}

@media (max-width: 1199px) {
  .calendar-span-3days .calendar-content {
    top: 4.05em;
  }
}

.calendar-span-week .calendar-content {
  top: 4.05em;
}

.calendar-content .hours {
  position: absolute;
  top: 0;
  left: 0;
  width: 2em;
  background: rgb(215, 219, 225);
}

.calendar-content .hour {
  height: 8em;
  -webkit-box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.3);
  padding: 0.5em 0.4em;
  text-align: right;
}

.calendar-content .hour:first-child {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.calendar-content .grid {
  position: absolute;
  top: 0;
  left: 2em;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.calendar-content .day {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.calendar-content .column {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  -webkit-box-shadow: inset 1px 0 0 rgba(0, 0, 0, .15);
  box-shadow: inset 1px 0 0 rgba(0, 0, 0, .15);
}

.calendar-content .block {
  height: 2em;
  -webkit-box-shadow: inset 0 1px 0 rgba(223, 227, 233, 1);
  box-shadow: inset 0 1px 0 rgba(223, 227, 233, 1);
}

.calendar-content .block.available:hover {
  background-color: rgba(236, 240, 246, 0.5);
}

.calendar-content .block:first-child {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.calendar-content .block.available {
  background: none;
}

.calendar-content .block.holiday {
  background: rgba(235, 198, 0, .3);
}

.calendar-content .hourblock span {
  font-size: 80%;
  color: #232934;
  position: absolute;
  overflow: hidden;
  display: none;
  margin: .2em;
  cursor: pointer;
}

.calendar-content .hourblock:hover span {
  display: block;
}

.calendar-content .event {
  position: absolute;
  left: 1px;
  right: 0;
  padding: 0.4em 0.5em 0 10px;
  line-height: 1.2;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}

.calendar-content .event:before,
.calendar-content .event:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 1px;
  left: 0;
  width: 4px;
}

.calendar-content .event:after {
  right: 0;
  width: auto;
  opacity: 0.3;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}

.calendar-content .event > * {
  position: relative;
  z-index: 1;
}

.calendar-content .event .time,
.calendar-content .event .title,
.calendar-content .event .customer,
.calendar-content .event .inline-icon,
.calendar-content .event .notes {
  font-size: 0.8em;
  display: inline;
  vertical-align: middle;
  margin: 0 0.5em 0 0;
}

.calendar-content .event .inline-icon {
  vertical-align: baseline;
}

.calendar-content .event .title {
  font-weight: bold;
}

.calendar-content .event .notes {
  font-style: italic;
}

.calendar-content .event .appointment-badge {
  position: absolute;
  bottom: .25em;
  right: .25em;
  font-size: 14px;
}

.calendar-content .event .appointment-badge + .appointment-badge {
  right: 1.5em;
}

.calendar-content .event .appointment-badge + .appointment-badge + .appointment-badge {
  right: 2.75em;
}

.calendar-content .event .appointment-badge + .appointment-badge + .appointment-badge + .appointment-badge {
  right: 4em;
}

.calendar-content .event .appointment-badge + .appointment-badge + .appointment-badge + .appointment-badge + .appointment-badge {
  right: 5.25em;
}

.calendar-content .event .resize {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.5em;
  cursor: row-resize;
  display: none;
}

.calendar-content .event .resize:after {
  content: "";
  position: absolute;
  bottom: 4px;
  left: 50%;
  width: 1em;
  margin-left: -0.5em;
  height: 2px;
  border-top: 1px solid rgba(255, 255, 255, 0.6);
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}

.calendar-content .event:hover .resize {
  display: block;
}

.calendar-span-3days .calendar-content .event {
  line-height: 1;
}

.calendar-span-3days .calendar-content .event .time,
.calendar-span-3days .calendar-content .event .title,
.calendar-span-3days .calendar-content .event .customer,
.calendar-span-3days .calendar-content .event .notes {
  font-size: 0.6em;
}

.calendar-span-3days .calendar-content .event .title:after {
  content: " ";
}

.calendar-span-week .calendar-content .event {
  line-height: 0.7em;
}

.calendar-span-week .calendar-content .event .time,
.calendar-span-week .calendar-content .event .title,
.calendar-span-week .calendar-content .event .customer,
.calendar-span-week .calendar-content .event .notes {
  font-size: 0.5em;
}

.calendar-span-week .calendar-content .event .title:after {
  content: " ";
}

@media (max-width: 479px) {
  .calendar-content .event {
    line-height: 1.1;
  }
  .calendar-content .event .time,
  .calendar-content .event .title,
  .calendar-content .event .customer,
  .calendar-content .event .notes {
    font-size: 0.6em;
  }
  .calendar-content .event .title:after {
    content: " ";
  }
}

.calendar-content .category-a {
  color: #1553c1;
}

.calendar-content .category-a:before,
.calendar-content .category-a:after {
  background: #5693ff;
}

.calendar-content .category-b {
  color: #b02a1c;
}

.calendar-content .category-b:before,
.calendar-content .category-b:after {
  background: #f86454;
}

.calendar-content .category-c {
  color: #2d4910;
}

.calendar-content .category-c:before,
.calendar-content .category-c:after {
  background: #6dc811;
}

.calendar-content .category-d {
  color: #61540b;
}

.calendar-content .category-d:before,
.calendar-content .category-d:after {
  background: #ebc600;
}

.calendar-content .category-e {
  color: #81590e;
}

.calendar-content .category-e:before,
.calendar-content .category-e:after {
  background: #ffab0f;
}

.calendar-content .category-f {
  color: #721bbe;
}

.calendar-content .category-f:before,
.calendar-content .category-f:after {
  background: #b15efb;
}

.calendar-content .event:hover,
.calendar-content .event.active,
.calendar-content .event-group:hover .event,
.calendar-content .event-group.active .event {
  color: #fff;
  z-index: 10;
}

.calendar-content .event:hover:after,
.calendar-content .event.active:after,
.calendar-content .event-group:hover .event:after,
.calendar-content .event-group.active .event:after {
  opacity: 1;
}

.current-time {
  position: absolute;
  left: -2em;
  right: 0;
  z-index: 2;
  border-top: 1px solid #ff3c27;
}

.current-time .time {
  position: absolute;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #ff3c27;
  font-size: 0.7em;
  background: rgba(211, 215, 221, 1);
  padding: 0.2em;
}

.calendar-date-picker {
  max-width: 20em;
  margin: 0 auto;
}

.calendar-add-appointment-time .datepicker {
  margin-bottom: 2em;
}

.calendar-add-appointment-service {
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
}

.calendar-add-appointment-service .services-navigator {
  position: absolute;
  width: 100%;
  height: 100%;
}

@media (max-width: 767px) {
  .calendar-add-appointment-service {
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    margin-left: -1em;
    margin-right: -1em;
  }
  .calendar-add-appointment-service .services-navigator {
    position: static;
    width: auto;
  }
}

.calender-menu-options {
  display: none;
  position: absolute;
  top: 64px;
  right: 4px;
  padding: 10px;
  background-color: #232934;
  border-radius: 4px;
}

@media (max-width: 479px) {
  .calender-menu-options {
    top: 50px;
  }
}

.calender-menu-view-options {
  float: right;
  display: none;
  top: 0;
  background-color: #232934;

  .toolbar-button {
    height: 36px;
    margin: 4px;
    display: block;
  }
}

.calendar-menu-view-persons {
  clear: right;
  display: none;
  top: 45px;
  background-color: #232934;

  .toolbar-button {
    height: 50px;
    margin: 4px;

    img {
      vertical-align: middle;
    }
  }
}

.calendar-menu-toggle-persons-no-working-hours {
  display: flex;
  align-items: center;
  margin: 16px 8px 8px 8px;
  color: #fff;
  justify-content: flex-end;

  .toggle-checkbox {
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin: 0
  }

  .toggle-checkbox + label {
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    margin-left: 8px;
  }
}

.datepicker-block .datepicker {
  margin: 0 auto 2em auto;

  .datepicker-calendar {
    .available,
    .busy,
    .full {
      color: #000;
    }

    .day.selected {
      color: #fff;

      &:before,
      &:hover:before {
        background-color: rgba(0, 0, 0, .5);
      }
    }

    .disabled.available,
    .disabled.busy {
      background-color: rgba(0, 0, 0, 0.15);
    }

    .disabled.available:hover:before,
    .disabled.available:before,
    .disabled.busy:hover:before,
    .disabled.busy:before,
    .disabled.full:hover:before,
    .disabled.full:before {
      background-color: transparent;
    }

    .available:hover:before,
    .available:before {
      background-color: rgba($color-success, .5);

    }

    .busy:hover:before,
    .busy:before {
      background-color: rgba($color-warning, .5);
    }

    .full:hover:before,
    .full:before {
      background-color: rgba($color-error, .5);
    }
  }
}

.times-block {
  max-width: 620px;

  .button.small {
    width: 72px;
  }

  .time-slot-is-adjacent,
  .time-slot-is-double-adjacent {
    font-weight: bold;
    color: $color-success;
  }
}

.calender-menu-view-sliders {
  color: white;
  margin: 8px;
  display: flex;
  flex-wrap: wrap;
  min-width: 150px;
  justify-content: flex-end;

  > div {
    flex-grow: 1;
    max-width: 300px;
    min-width: 150px;

    > div {
      margin: 8px 4px;
    }
  }
}
