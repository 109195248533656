.feedback-page {
  .feedback-form-container {
    max-width: 35em;

    .feedback-form {
      background-color: white;
      padding: 2em;
      border-radius: 20px;
      opacity: 0.8;

      .feedback-options {
        #rating-images {
          display: flex;
          justify-content: space-evenly;

          label {
            display: inline-block;
            width: fit-content;
            padding: 3px;

            img {
              width: 100%;
              height: auto;
              max-width: 128px;
            }
          }
        }
      }
    }
  }
}
