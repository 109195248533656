/* TOOLBAR */
.toolbar {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 5em;
  right: 0;
  background: #303640;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  justify-content: space-between;
  padding: 12px 10px;
  font-size: 16px;
}

.toolbar .segment {
  display: inline-flex;
  vertical-align: middle;
  position: relative;
}

.toolbar .short,
.toolbar .shorter {
  display: none;
}

.toolbar .segment .weekjump {
  position: absolute;
  background-color: #232934;
  z-index: 20;
  top: 110%;
  box-sizing: border-box;
  border-radius: 4px;
  display: none;
}

.toolbar .segment .weekjump.backward {
  left: 0;
}

.toolbar .segment .weekjump.forward {
  right: 0;
}

.weekjump a {
  display: block;
  padding: 5px 10px;
  color: #FFFFFF;
  background-color: rgba(255, 255, 255, 0.1);

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

}

.main-content > .toolbar {
  position: static;
}

@media (max-width: 1023px) {
  .toolbar {
    left: 3.75em;
  }
}

@media (max-width: 767px) {
  .toolbar {
    left: 48px;
    padding: 6px 4px;
  }
}

@media (max-width: 991px) {
  .toolbar .short {
    display: inline;
  }
  .toolbar .long {
    display: none;
  }
}

@media (max-width: 450px) {
  .toolbar .current-date-button {
    padding: 0 .8em;

    .shorter {
      text-transform: initial;
      display: inline;
    }

  }
  .toolbar .current-date-button .short {
    display: none;
  }
}

@media (max-width: 320px) {
  .toolbar .current-date-button .shorter {
    display: none;
  }
}

.toolbar-button {
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
  padding: 0.6em 1em;
  margin: 0 1px;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(0, 0, 0, 0.5), 0 1px 3px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(0, 0, 0, 0.5), 0 1px 3px rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  display: inline-block;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  cursor: pointer;
  vertical-align: middle;
}

.toolbar-button option {
  color: #232934;
}

.segment .toolbar-button {
  border-radius: 0;
}

.segment .toolbar-button:first-child {
  border-radius: 4px 0 0 4px;
}

.segment .toolbar-button:nth-child(6) {
  border-radius: 0 4px 4px 0;
}

.toolbar-button:hover,
.toolbar-button:focus,
.toolbar-button:active {
  background: rgba(255, 255, 255, 0.2);
}

.toolbar-button.selected {
  background: #2164db;
}

.toolbar-button.disabled {
  background: dimgrey;
  color: darkgrey;
  cursor: not-allowed;
}

@media (max-width: 767px) {
  .toolbar-button {
    height: 36px;
  }
}

@media (max-width: 479px) {
  .toolbar-button {
    padding: 0.6em 0.8em 0;
  }
}

@media (max-width: 359px) {
  .toolbar-button {
    padding: 0.6em 0.6em 0;
  }
}

@media (min-width: 768px) {
  .has-toolbar {
    margin-top: 60px;
  }
}

.toolbar {
  .calendar-actions-dropdown-menu {
    position: relative;
    display: inline-block;

    .toolbar-button {
      border-radius: 0 !important;
    }

    &:hover {
      .calendar-actions-dropdown-menu-content {
        display: block;
      }
    }

    .calendar-actions-dropdown-menu-content {
      display: none;
      position: absolute;
      background: #232934;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;

      .toolbar-button {
        display: block;
        border-radius: 0 !important;
      }

      .calendar-actions-dropdown-menu-text {
        margin-left: 0.5em;
      }
    }
  }
}
