/* STATUS MESSAGES */
.status-message {
  color: #fff;
  padding: 1em 3em 0.01em 1em;
  margin: 0 0 1.5em;
  position: relative;

  a {
    color: #fff;
    text-decoration: underline;
  }

  ul {
    margin-bottom: 1em;
    padding-left: 1em;

    li {
      margin-bottom: 0.5em;
      list-style: disc;
    }
  }
}

.status-message .status-message-close {
  position: absolute;
  font-size: 1.2em;
  top: 1em;
  right: 1em;
  color: #fff;
}

.status-message.success {
  background: $color-success;
}

.status-message.error {
  background: $color-error;
}

.status-message.info {
  background: $color-info;
}

.status-message.warning {
  background: $color-warning;
}

.status-message.announcement {
  background-color: rgba(33, 100, 219, 0.4);
  border-radius: 20px;
  padding: 0;

  p {
    padding: 5px;
    font-size: 0.9em;
    margin: 1em 3em;
  }
}

.status-messages {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
}

.status-messages .status-message {
  margin: 0;
}

.multiline {
  padding-bottom: 1em;
}

.text-success {
  color: $color-success;
  font-weight: bold;
}

.text-info {
  color: $color-info;
  font-weight: bold;
}

.text-error {
  color: $color-error;
  font-weight: bold;
}
