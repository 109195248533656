/* SERVICES NAVIGATOR */
.activity-search-form {
  .icon-search {
    margin: 0 0.5em 0 0;
    line-height: 0;
  }

  &.active {
    input {
      display: block;
    }

    .placeholder {
      display: none;
    }
  }
}

.services-navigator {
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  .item {
    display: block;
    border-bottom: 1px solid #E5E5E5;
    position: relative;
    padding: 1.375em 2.2em 1.375em 5em;
    cursor: pointer;
    color: #2164db;

    &.item--two-line {
      padding: .875em 2.2em .875em 5em;
    }

    &.active {
      background-color: #2164db;
      color: #fff;

      a,
      button {
        color: #fff;
      }
    }

    &.is-activity .graphic {
      border-radius: 50%;
    }

    .graphic {
      position: absolute;
      left: 0;
      top: 0;
      height: 3.75em;
      width: 3.75em;
      background: rgba(0, 0, 0, 0.1);
      text-align: center;

      .icon {
        position: static;
        font-size: 1.5em;
        line-height: 2.5em;
      }
    }

    .label {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .icon {
      position: absolute;
      right: 0.8em;
      top: 50%;
      transform: translateY(-50%);
      line-height: 3.75em;
      padding-right: .8em;
      padding-left: .8em;
    }

    .actions {
      position: absolute;
      right: 0;
      top: 1.4em;

      .icon {
        position: static;
        padding-left: 0;
        line-height: normal;
      }

      .badge {
        position: absolute;
        top: 0;
        right: 1em;
        font-size: .6em;
      }

      > a {
        position: relative;
      }
    }

    &.closed {
      .label {
        visibility: visible;
      }

      .actions a,
      .actions button {
        display: none;

        &.toggle-actions {
          display: block;
        }
      }
    }

    &.open {
      .label {
        visibility: hidden;
      }

      .actions {
        display: block;

        .toggle-actions {
          color: $color-success;
        }
      }
    }
  }

  .return,
  .action {
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0.8em;
    background: rgba(0, 0, 0, 0.02);
  }

  .action {
    padding: 0;

    a {
      padding: .8em;
      display: block;
    }
  }
}

.services-navigator-multiple {
  overflow-x: auto;
  display: flex;
  position: relative;
  padding-bottom: 2em;

  .services-navigator {
    min-width: 300px;

    .item {
      &.is-activity .graphic {
        border-radius: 50%;
      }

      .label {
        max-width: 220px;
        display: block;
      }
    }
  }
}
