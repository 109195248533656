table.price-list-report{
  width: 100%;
  margin-bottom: 30px;
}

table.price-list-report:not(.not-striped) tbody > tr:nth-of-type(odd),
table.price-list-report tbody > tr.odd {
  background-color: #fff;
}

table.price-list-report{
  font-size: 19px;
}

table.price-list-report th{
  font-weight: bold;
  font-size: 22px;
  text-transform: capitalize;
}

table.price-list-report th{
  border-top: none;
}

.price-list-report-h2{
  font-size: 40px;
}
