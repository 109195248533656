/* PICTURE UPLOAD */
.picture-upload-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  position: relative;
  min-height: 8em;
}
.picture-upload-wrapper .picture-upload {
  padding: 1em 2em;
  margin: 0 0 1em;
  border: 4px dashed rgba(0,0,0,0.15);
  border-radius: 15px;
  text-align: center;
  color: rgba(0,0,0,0.4);
  font-size: 0.9em;
  line-height: 1.2;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.picture-upload-wrapper .picture-upload.not-empty {
  border-color: #69d321;
}
.picture-upload-wrapper .picture-upload input {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0;
}
.picture-upload-wrapper .picture-upload .text {
  width: 100%;
  flex: auto;
}
.picture-upload-wrapper img {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.picture-upload-wrapper .remove-picture {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  background: rgba(0,0,0,0.3);
  padding: 0.2em 0.4em;
  border-radius: 99px;
  color: #fff;
  line-height: 1;
  font-size: 0.8em;
}
.picture-upload-wrapper .remove-picture .icon {
  margin: 0 0.25em 0 0;
}
.picture-upload-wrapper .remove-picture:hover {
  background: #000;
}

label .picture-upload-wrapper {
  width: 100%;
}
