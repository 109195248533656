
@font-face {
  font-family: "optios";
  src:url("../fonts/optios/optios.eot");
  src:url("../fonts/optios/optios.eot?#iefix") format("embedded-opentype"),
  url("../fonts/optios/optios.woff") format("woff"),
  url("../fonts/optios/optios.ttf") format("truetype"),
  url("../fonts/optios/optios.svg#optios") format("svg");
  font-weight: normal;
  font-style: normal;

}
.icon:before {
  font-family: "optios" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  top: 2px;
}

.icon-phone:before {
  content: "f095"
}


.icon-support:before {
  content: "\61";
}
.icon-register:before {
  content: "\63";
}
.icon-products:before {
  content: "\64";
}
.icon-logout:before {
  content: "\62";
}
.icon-calendar-neg:before {
  content: "\65";
}
.icon-customers-neg:before {
  content: "\66";
}
.icon-check-circle:before {
  content: "\67";
}
.icon-back:before {
  content: "\68";
}
.icon-delete:before {
  content: "\69";
}
.icon-forward:before {
  content: "\6a";
}
.icon-select:before {
  content: "\6b";
}
.icon-color:before {
  content: "\6c";
}
.icon-checkbox:before {
  content: "\6d";
}
.icon-reports-neg:before {
  content: "\6e";
}
.icon-plus:before {
  content: "\6f";
}
.icon-trusted:before {
  content: "\70";
}
.icon-online-neg:before {
  content: "\71";
}
.icon-zoom-out:before {
  content: "\72";
}
.icon-zoom-in:before {
  content: "\73";
}
.icon-today:before {
  content: "\74";
}
.icon-woman:before {
  content: "\75";
}
.icon-settings-neg:before {
  content: "\76";
}
.icon-minus:before {
  content: "\77";
}
.icon-support-neg:before {
  content: "\78";
}
.icon-close:before {
  content: "\79";
}
.icon-menu:before {
  content: "\7a";
}
.icon-search:before {
  content: "\41";
}
.icon-calendar:before {
  content: "\42";
}
.icon-home:before {
  content: "\43";
}
.icon-punchclock:before {
  content: "\44";
}
.icon-customers:before {
  content: "\45";
}
.icon-online:before,
.icon-website:before{
  content: "\46";
}
.icon-reports:before {
  content: "\47";
}
.icon-settings:before {
  content: "\48";
}
.icon-register-neg:before {
  content: "\49";
}
.icon-products-neg:before {
  content: "\4a";
}
.icon-punchclock-neg:before {
  content: "\4b";
}
.icon-add:before {
  content: "\4c";
}
.icon-edit:before {
  content: "\4d";
}
.icon-notice:before {
  content: "\4e";
}
.icon-man:before {
  content: "\4f";
}
.icon-actions:before {
  content: "\50";
}
.icon-copy:before {
  content: "\51";
}
.icon-password:before {
  content: "\52";
}
.icon-print:before {
  content: "\53";
}
.icon-scissors:before {
  content: "\54";
}
.icon-thumb-tack:before {
  content: "\55";
}
.icon-flag:before {
  content: "\56";
}
.icon-color-2:before {
  content: "\58";
}
.icon-chat:before {
  content: "\59";
}
.icon-brush:before {
  content: "\57";
}
.icon-marketing-neg:before {
  content: "\5a";
}
.icon-marketing:before {
  content: "\30";
}
.icon-wallet:before {
  content: "\31";
}
.icon-pdf:before {
  content: "\33";
}
.icon-knowledge:before {
  content: "\32";
}
.icon-permissions:before {
  content: "\35";
}
.icon-birthday:before {
  content: "\34";
}
.icon-tickets:before {
  content: "\36";
}
.icon-categories:before {
  content: "\38";
}
.icon-supplier:before {
  content: "\39";
}
.icon-department:before {
  content: "\37";
}
.icon-dropdown:before,
.icon-expanded:before {
  content: "\22";
}
.icon-lock:before {
  content: "\21";
}
.icon-unlock:before {
  content: "\23";
}
.icon-sms:before {
  content: "\24";
}
.icon-hardware:before {
  content: "\25";
}
.icon-loyalty-card:before,
.icon-loyaltycard:before{
  content: "\26";
}
.icon-subscription:before,
.icon-refresh:before{
  content: "\27";
}
.icon-add-circle:before {
  content: "\28";
}
.icon-sun:before {
  content: "\29";
}
.icon-trash:before {
  content: "\2a";
}
.icon-modules:before {
  content: "\2b";
}
.icon-info:before {
  content: "\2c";
}
.icon-attachment:before {
  content: "\2d";
}
.icon-pipedrive:before {
  content: "\2e";
}
.icon-email:before {
  content: "\3a";
}
.icon-skintrack:before {
  content: "\3b";
}
.icon-collapsed:before {
  content: "\2f";
}
.icon-angle-double-left:before {
  content: "\3c";
}
.icon-angle-double-right:before {
  content: "\3d";
}
.icon-angle-left:before {
  content: "\3e";
}
.icon-angle-right:before {
  content: "\3f";
}
.icon-last-minute:before {
  content: "\40";
}
.icon-waiting-list:before {
  content: "\5d";
}
.icon-diamond:before {
  content: "\5b";
}
.icon-workshop:before {
  content: "\5e";
}
