/* TABS */
.tab-lists-vertically {
  .tab-customer-basic,
  .tab-customer-advanced,
  .tab-customer-partners,
  .tab-calendar-basic,
  .tab-calendar-advanced,
  .tab-calendar-partners,
  .tab-communication-email,
  {
    width: 10em;
  }
}

.tab,
.tab-list-horizontal,
.tab-list-vertical,
.tab-customer-basic ,
.tab-customer-advanced,
.tab-customer-partners,
{
  display: none;

  &.active {
    display: block;
  }

  .blank-slate {
    margin-top: 0;
    font-size: 20vw;
  }
}

.segment-tabs {
  text-align: center;
  margin: 0 0 2em;
  text-transform: uppercase;

  a {
    display: inline-block;
    padding: 0.8em 1em;
    border: 2px solid #2164db;
    border-right: 0;
    margin-left: -5px;

    &:first-child {
      border-radius: 99px 0 0 99px;
    }

    &:last-child {
      border-radius: 0 99px 99px 0;
      border-right: 2px solid #2164db;
    }

    &:only-child {
      border-radius: 99px 99px 99px 99px;
    }
  }

  .selected {
    background: #2164db;
    color: #fff;
  }
}

@media (max-width: 479px) {
  .segment-tabs {
    font-size: 0.75em;
  }
}

@media (max-width: 359px) {
  .segment-tabs {
    font-size: 0.65em;
  }
}
