.filter-bar {
  background: rgba(215,224,236,0.9);
  padding: 0.6em 1em;

  .select {
    margin: 0 1em 0 0;
  }

  p {
    margin-bottom: 0;
  }

  &.warning {
    background: rgba($color-warning, 0.9);
  }
}

.products-bar {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}
