.hairco-product-changes {
  .previous-value {
    color: $color-error;
    position: relative;
    min-width: 1em;
    display: inline-block;
    text-align: center;

    &:after {
      content: '';
      position: absolute;
      border-bottom: 2px solid $color-error;
      transform: rotate(45deg);
      width: 100%;
      left: 0;
      top: 50%;
    }
  }

  .new-value {
    color: $color-success;
    font-weight: bold;
  }
}
