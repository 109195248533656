@import "variables";
@import "reset";
@import "icons";
@import "components/spinner";
@import "components/datepicker";
@import "components/dialog";
@import "components/status-message";
@import "components/filterbar";
@import "components/servicesnavigator";
@import "components/lockscreen";
@import "components/toolbar";
@import "components/picture_upload";
@import "components/tabs";
@import "components/report";
@import "form";
@import "buttons";
@import "responsive";
@import "components/cards";
@import "helpers";
@import "fontawesome/fontawesome";
@import "fontawesome/solid";
@import "pages/calendar";
@import "pages/requisition";
@import "pages/feedback-page";

.hide {
  display: none !important;
}

a {
  cursor: pointer;
}

[draggable] {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  /* Required to make elements draggable in old WebKit */
  -khtml-user-drag: element;
  -webkit-user-drag: element;
}

.over {
  border: 2px dashed #000;
}

/* BREAKPOINTS */
body {
  font: 100%/1 proxima-nova, sans-serif;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  background: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  line-height: 1.5;
}

a {
  text-decoration: none;
  color: #2164db;
}

h1 {
  font-weight: normal;
  font-size: 1.75em;
  margin: 1em 0;
}

h2 {
  font-weight: normal;
  font-size: 1.5em;
  margin: 1em 0;
}

h3 {
  font-weight: normal;
  font-size: 1.25em;
  margin: 1em 0;
}

.main-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

/* PRIMARY */
.main-nav {
  background: #232934;
  -webkit-flex: 0 0 5em;
  -ms-flex: 0 0 5em;
  flex: 0 0 5em;
  padding: 0 0 1em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.main-nav a {
  text-align: center;
  height: 5em;
  color: rgba(255, 255, 255, 0.4);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.main-nav a:hover,
.main-nav a.selected {
  color: #fff;
}

.main-nav .icon-off,
.main-nav a.selected .icon-on {
  display: none;
}

.main-nav a.selected .icon-off {
  display: block;
}

.main-nav .icon {
  font-size: 2.25em;
}

.main-nav .calendar.selected {
  background: #ffab0f;
}

.main-nav .customers.selected {
  background: #69d321;
}

.main-nav .register.selected {
  background: #8262d8;
}

.main-nav .products.selected {
  background: #a47433;
}

.main-nav .punchclock.selected {
  background: #f8549c;
}

.main-nav .reports.selected {
  background: #d24444;
}

.main-nav .marketing.selected {
  background: #24a7f3;
}

.main-nav .minor {
  font-size: 0.7em;
}

.main-nav .minor.selected {
  background: rgba(255, 255, 255, 0.2);
}

.main-nav .seperator {
  -webkit-flex: 1 1 2em;
  -ms-flex: 1 1 2em;
  flex: 1 1 2em;
}

@media (max-height: 800px) {
  .main-nav {
    padding-bottom: 0.5em;
  }
}

@media (max-width: 1023px) {
  .main-nav {
    -webkit-flex-basis: 3.75em;
    -ms-flex-basis: 3.75em;
    flex-basis: 3.75em;
  }
  .main-nav a {
    height: 3.75em;
  }
  .main-nav .icon {
    font-size: 1.75em;
  }
}

/* SECONDARY */
.secondary-nav {
  background: #303640;
  -webkit-flex: 0 0 12.22222em;
  -ms-flex: 0 0 12.22222em;
  flex: 0 0 12.22222em;
  font-size: 1.125em;
  padding: 1em 0 0;
  overflow: auto;
}

.secondary-nav .compact,
.secondary-nav .closeSecondaryNav {
  display: none;
}

.secondary-nav .nav-item {
  display: block;
  color: #4673f8;
  font-weight: 600;
  padding: 0.83333em 1em 0.83333em 1.2em;
  border-left: 4px solid transparent;
}

.secondary-nav a:hover {
  color: #fff;
}

.secondary-nav .selected {
  color: #fff;
  border-left-color: #fff;
}

@media (max-width: 1199px) {
  .secondary-nav {
    font-size: 1em;
    -webkit-flex-basis: 11.11111em;
    -ms-flex-basis: 11.11111em;
    flex-basis: 11.11111em;
  }
}

.nav-search-form .placeholder {
  cursor: pointer;
}

.nav-search-form .placeholder:hover {
  color: #fff;
}

.nav-search-form .icon {
  margin: 0 0.5em 0 0;
  line-height: 0;
}

.nav-search-form input {
  display: none;
  color: #fff;
  border: 0;
  background: none;
  height: 2em;
  padding: 0;
  width: 100%;
  margin: -0.5em 0;
  -webkit-appearance: none;
  appearance: none;
}

.nav-search-form.active input {
  display: block;
}

.nav-search-form.active .placeholder {
  display: none;
}

/* TERTIARY */
.tertiary-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.has-filterbar .tertiary-wrapper {
  top: 3em;
}

.tertiary-nav {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 280px;
  background: #ecf0f6;
}

.tertiary-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 280px;
  right: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.tertiary-nav-return {
  display: none;
  background: #ecf0f6;
  padding: 1em;
}

@media (max-width: 1199px) {
  .tertiary-nav {
    width: 240px;
  }
  .tertiary-container {
    left: 240px;
  }
}

@media (max-width: 1023px) {
  .tertiary-wrapper {
    overflow: hidden;
  }
  .tertiary-nav {
    width: 100%;
  }
  .tertiary-container {
    left: 100%;
    width: 100%;
  }
  .tertiary-nav-return {
    display: block;
  }
  .tertiary-nav,
  .tertiary-container {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .tertiary-open .tertiary-nav,
  .tertiary-open .tertiary-container {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

/* CONTENT */
.main-content {
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  font-size: 1.125em;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
}

@media (max-width: 1199px) {
  .main-content {
    font-size: 1em;
  }
}

@media (max-width: 767px) {
  .main-content {
    font-size: 0.9375em;
    z-index: -1;
  }
}

@media (max-width: 767px) {
  .main-wrapper {
    z-index: 1;
  }
}

@media (max-width: 599px) {
  .main-content {
    font-size: 0.875em;
  }
}

.page-layout {
  margin: 3em auto 5em auto;
  padding: 0 2em;
  max-width: 48em;

  &.large {
    max-width: 64em;
  }

  &.max {
    max-width: none;
  }
}

@media (max-width: 1199px) {
  .page-layout {
    margin-top: 2em;
    padding: 0 1.5em;
  }
}

@media (max-width: 599px) {
  .page-layout {
    margin-top: 1em;
    padding: 0 1em;
  }
}

/* LAYOUT SWITCH */
@media (max-width: 767px) {
  .main-nav {
    position: fixed;
    width: 48px;
    height: 48px;
    padding: 0;
  }
  .main-nav a,
  .main-nav .seperator,
  .main-nav .home .icon {
    display: none;
  }
  .main-nav .selected,
  .main-nav .home {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    height: 100%;
  }
  .main-nav .home {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
  .main-nav .icon {
    font-size: 1.5em;
  }
  .main-nav .minor {
    font-size: 1em;
  }
  .secondary-nav {
    position: fixed;
    z-index: 1;
    left: 48px;
    right: 0;
    width: auto;
    height: 48px;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0;
    font-size: 1.125em;
    overflow: visible;
  }
  .secondary-nav .compact {
    display: block;
  }
  .secondary-nav .compact .nav-item {
    padding: 0.83333em 0.5em;
    color: #fff;
  }

  .secondary-nav .compact .icon {
    margin: 0 0.5em 0 0;
  }
  .secondary-nav .full {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: #303640;
    padding: 0.3125em 0 0.7em;
  }
  .secondary-nav .full .nav-item {
    padding: 0.55556em 1em;
  }
  .secondary-nav .closeSecondaryNav {
    display: block;
    position: absolute;
    top: -3px;
    right: 0;
    color: #fff;
    padding: 1em;
  }
  .secondary-nav .nav-item {
    border: 0;
  }
  .secondary-nav.collapsed .full {
    display: none;
  }
  .main-content {
    margin-top: 48px;
  }

  .main-content.client-login {
    margin-top: 0;
  }
  .module-home .main-content,
  .module-login .main-content {
    margin-top: 0;
  }
}

/*** EARLY LAYOUT SWITCH! **/
@media (max-width: 1023px) {
  .large-mobile {
    .main-nav {
      position: fixed;
      width: 48px;
      height: 48px;
      padding: 0;
    }

    .main-nav a,
    .main-nav .seperator,
    .main-nav .home .icon {
      display: none;
    }

    .main-nav .selected,
    .main-nav .home {
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      width: 100%;
      height: 100%;
    }

    .main-nav .home {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }

    .main-nav .icon {
      font-size: 1.5em;
    }

    .main-nav .minor {
      font-size: 1em;
    }

    .secondary-nav {
      position: fixed;
      z-index: 1;
      left: 48px;
      right: 0;
      width: auto;
      height: 48px;
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      padding: 0;
      font-size: 1.125em;
      overflow: visible;
    }

    .secondary-nav .compact {
      display: block;
    }

    .secondary-nav .compact .nav-item {
      padding: 0.83333em 0.5em;
      color: #fff;
    }

    .secondary-nav .compact .icon {
      margin: 0 0.5em 0 0;
    }

    .secondary-nav .full {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background: #303640;
      padding: 0.3125em 0 0.7em;
    }

    .secondary-nav .full .nav-item {
      padding: 0.55556em 1em;
    }

    .secondary-nav .closeSecondaryNav {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      color: #fff;
      padding: 1em;
    }

    .secondary-nav .nav-item {
      border: 0;
    }

    .secondary-nav.collapsed .full {
      display: none;
    }

    .main-content {
      margin-top: 48px;
    }

    .module-home .main-content,
    .module-login .main-content {
      margin-top: 0;
    }

    .dialog-wrapper {
      left: 0;
    }
  }
}

.blank-slate {
  font-size: 30vw;
  margin-top: 20vh;
  text-align: center;
  color: rgba(0, 0, 0, 0.03);
}

.alphabet-scrubber {
  position: absolute;
  right: 0;
  top: 2em;
  bottom: 0.5em;
  padding: 0.5em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.alphabet-scrubber a {
  background: rgba(215, 224, 236, 0.9);
  padding: 0 0.4em;
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  max-height: 2em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.alphabet-scrubber a:first-child {
  border-radius: 99px 99px 0 0;
  padding-top: 0.5em;
  max-height: 2.5em;
}

.alphabet-scrubber a:last-child {
  border-radius: 0 0 99px 99px;
  padding-bottom: 0.5em;
  max-height: 2.5em;
}

.badge {
  display: inline-block;
  padding: 0.3em 0.3em;
  margin: -0.15em 0;
  line-height: 1;
  background: $color-primary;
  color: #fff;
  border-radius: 99px;

  &.destructive {
    background-color: $color-destructive;
  }
}

.home-wrapper {
  text-align: center;
  min-height: 100vh;
  padding: 5vh 0 1.5em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background: rgb(70, 115, 248);
  background: linear-gradient(
                  135deg,
                  rgba(70, 115, 248, 1) 0%,
                  rgba(143, 93, 226, 1) 50%,
                  rgba(210, 78, 189, 1) 100%
  );
}

.module-home .home-wrapper {
  font-size: calc(1em + 2 * (100vw - 600px) / 400);
}

.home-logo {
  max-width: 21em;
  max-height: 10vh;
  margin: 0 0 5vh;
}

@media (max-width: 767px) {
  .home-logo {
    margin-left: 1.5em;
  }
}

.home-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 0.4em;
  width: 100%;
  max-width: 70vw;
  margin: 0 0 3vh;
}

.home-grid a {
  -webkit-flex: 0 1 25%;
  -ms-flex: 0 1 25%;
  flex: 0 1 25%;
  padding: 0.2em;
  color: #fff;
}

.home-grid .home-button {
  background-color: rgba(255, 255, 255, 0.44);
  border-radius: 30px;
  text-transform: uppercase;
  padding: 1.5em 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-duration: 0.65s;
  font-weight: 800;
  font-style: normal;
  letter-spacing: 0.16em;
  line-height: 1.1em;
}

.home-grid .home-button * {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.home-grid .calendar:hover {
  color: #ffab0f;
}

.home-grid .customers:hover {
  color: #69d321;
}

.home-grid .register:hover {
  color: #8262d8;
}

.home-grid .products:hover {
  color: #a47433;
}

.home-grid .punchclock:hover {
  color: #f8549c;
}

.home-grid .reports:hover {
  color: #d24444;
}

.home-grid .marketing:hover {
  color: #24a7f3;
}

.home-grid .webinars:hover {
  color: #c191f6;
}

.home-grid .icon {
  display: block;
  font-size: 6rem;
  margin: 0 0 0.3em;
}

@media (max-width: 1199px) {
  .home-grid {
    max-width: 85vw;
  }
}

@media (max-width: 1023px) {
  .home-grid {
    max-width: 90vw;
  }
}

@media (max-width: 767px) {
  .home-grid .home-button {
    /*-webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 2px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 2px 1px rgba(0, 0, 0, 0.1);*/
  }
}

@media (max-width: 599px) {
  .home-grid {
    max-width: 95vw;
  }
  .home-grid a {
    -webkit-flex-basis: 33.33333%;
    -ms-flex-basis: 33.33333%;
    flex-basis: 33.33333%;
  }
  .home-grid .home-button {
    padding: 1em 0;
    height: 120px;
  }
  .home-grid .icon {
    font-size: 4em;
    margin: 0 0 0.2em;
  }
}

@media (max-width: 359px) {
  .home-grid {
    max-width: none;
  }
}

.home-form {
  width: 100%;
  max-width: 24em;
  padding: 0 1.2em;
  margin: 0 0 6vh;
}

.home-form label {
  text-align: left;
}

.home-form .forgot-password {
  display: block;
  margin: 2em 0 0;
  font-size: 0.9em;
  color: rgba(0, 0, 0, 0.5);
}

.wrapper-invite-friends {
  display: flex;
  margin: 16px 16px 4vh 16px;

}

.button-invite-friends {
  background-color: rgba(255, 0, 0, .44);
  border-radius: 30px;
  text-transform: uppercase;
  padding: 1.4em 1em;
  color: #fff;
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transition-duration: .65s;
  -o-transition-duration: .65s;
  transition-duration: .65s;
  font-style: normal;
  letter-spacing: 0.16em;
  line-height: 0;
  min-width: 300px;
  font-size: 0.75em;
  height: 16px;
}

.button-invite-friends:hover {
  background-color: rgba(0, 0, 0, .8);
  cursor: pointer;
}

.home-secondary-navigation {
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  margin: 0 0 5vh;
}

.home-secondary-navigation a {
  text-transform: uppercase;
  font-size: 0.9em;
  color: rgba(0, 0, 0, 1);
  margin: 0 1em;
  white-space: nowrap;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: none;
}

.modal-settings {
  position: fixed;
  z-index: 2;
  padding-top: 40px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.cards-settings-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  padding: 14px;
}

.icon-card {
  display: flex;
  padding: 0.9em 0.75em 0.75em;
  margin: 0.5em 0.75em 0.75em;
  background-color: #fff;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  width: 120px;
  height: 120px;
  max-width: 120px;
  max-height: 120px;
  aspect-ratio: 1/1;
  flex-grow: 1;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);

  // Account
  .card-icon-account,
  .card-text-account {
    color: #c191f6;
  }

  &.icon-card-account:hover {
    cursor: pointer;
    background-color: #c191f6;

    .card-icon-account {
      color: #ffffff;
    }

    .card-text-account {
      color: #ffffff;
    }
  }

  // Users
  .card-icon-users,
  .card-text-users {
    color: #ff982d;
  }

  &.icon-card-users:hover {
    cursor: pointer;
    background-color: #ff982d;

    .card-icon-users {
      color: #ffffff;
    }

    .card-text-users {
      color: #ffffff;
    }
  }

  // Communication
  .card-icon-communication,
  .card-text-communication {
    color: #24a7f3;
  }

  &.icon-card-communication:hover {
    cursor: pointer;
    background-color: #24a7f3;

    .card-icon-communication {
      color: #ffffff;
    }

    .card-text-communication {
      color: #ffffff;
    }
  }

  // Customers
  .card-icon-customers,
  .card-text-customers {
    color: #69d321;
  }

  &.icon-card-customers:hover {
    cursor: pointer;
    background-color: #69d321;

    .card-icon-customers {
      color: #ffffff;
    }

    .card-text-customers {
      color: #ffffff;
    }
  }

  // Calendar
  .card-icon-calendar,
  .card-text-calendar {
    color: #ffab0f;
  }

  &.icon-card-calendar:hover {
    cursor: pointer;
    background-color: #ffab0f;

    .card-icon-calendar {
      color: #ffffff;
    }

    .card-text-calendar {
      color: #ffffff;
    }
  }

  // Absence
  .card-icon-absence,
  .card-text-absence {
    color: #ff6d54;
  }

  &.icon-card-absence:hover {
    cursor: pointer;
    background-color: #ff6d54;

    .card-icon-absence {
      color: #ffffff;
    }

    .card-text-absence {
      color: #ffffff;
    }
  }

  // Activities
  .card-icon-activities,
  .card-text-activities {
    color: #ff5475;
  }

  &.icon-card-activities:hover {
    cursor: pointer;
    background-color: #ff5475;

    .card-icon-activities {
      color: #ffffff;
    }

    .card-text-activities {
      color: #ffffff;
    }
  }

  // Menus
  .card-icon-menus,
  .card-text-menus {
    color: #ff73b1;
  }

  &.icon-card-menus:hover {
    cursor: pointer;
    background-color: #ff73b1;

    .card-icon-menus {
      color: #ffffff;
    }

    .card-text-menus {
      color: #ffffff;
    }
  }

  // Register
  .card-icon-register,
  .card-text-register {
    color: #8262d8;
  }

  &.icon-card-register:hover {
    cursor: pointer;
    background-color: #8262d8;

    .card-icon-register {
      color: #ffffff;
    }

    .card-text-register {
      color: #ffffff;
    }
  }

  // Employees
  .card-icon-employees,
  .card-text-employees {
    color: #f8549c;
  }

  &.icon-card-employees:hover {
    cursor: pointer;
    background-color: #f8549c;

    .card-icon-employees {
      color: #ffffff;
    }

    .card-text-employees {
      color: #ffffff;
    }
  }

  // Website
  .card-icon-website,
  .card-text-website {
    color: #f8549c;
  }

  &.icon-card-website:hover {
    cursor: pointer;
    background-color: #f8549c;

    .card-icon-website {
      color: #ffffff;
    }

    .card-text-website {
      color: #ffffff;
    }
  }

  // Webshop
  .card-icon-webshop,
  .card-text-webshop {
    color: #c191f6;
  }

  &.icon-card-webshop:hover {
    cursor: pointer;
    background-color: #c191f6;

    .card-icon-webshop {
      color: #ffffff;
    }

    .card-text-webshop {
      color: #ffffff;
    }
  }

  // Online-payments
  .card-icon-online-payments,
  .card-text-online-payments {
    color: #d24444;
  }

  &.icon-card-online-payments:hover {
    cursor: pointer;
    background-color: #d24444;

    .card-icon-online-payments {
      color: #ffffff;
    }

    .card-text-online-payments {
      color: #ffffff;
    }
  }

  // Shop
  .card-icon-shop,
  .card-text-shop {
    color: #ff7f5f;
  }

  &.icon-card-shop:hover {
    cursor: pointer;
    background-color: #ff7f5f;

    .card-icon-shop {
      color: #ffffff;
    }

    .card-text-shop {
      color: #ffffff;
    }
  }

  // Marketing
  .card-icon-marketing,
  .card-text-marketing {
    color: #24a7f3;
  }

  &.icon-card-marketing:hover {
    cursor: pointer;
    background-color: #24a7f3;

    .card-icon-marketing {
      color: #ffffff;
    }

    .card-text-marketing {
      color: #ffffff;
    }
  }

  // App store
  .card-icon-app-store,
  .card-text-app-store {
    color: #8262d8;
  }

  &.icon-card-app-store:hover {
    cursor: pointer;
    background-color: #8262d8;

    .card-icon-app-store {
      color: #ffffff;
    }

    .card-text-app-store {
      color: #ffffff;
    }
  }
}

.card-text {
  line-height: 1.2;
  margin: 0;
  text-align: center;
  font-size: 0.65em;
  //&.icon-card-account:hover,
  //&.icon-card-users:hover,
  //&.icon-card-customers:hover,
  //&.icon-card-calendar:hover,
  //&.icon-card-absence:hover,
  //&.icon-card-activities:hover,
  //&.icon-card-menus:hover,
  //&.icon-card-register:hover,
  //&.icon-card-employees:hover,
  //&.icon-card-website:hover,
  //&.icon-card-webshop:hover,
  //&.icon-card-online-payments:hover,
  //&.icon-card-shop:hover,
  //&.icon-card-marketing:hover {
  //  color: #ffffff;
  //}
}

.card-icon {
  font-size: 2.6em;
  height: 80%;
  margin-left: 2%;

  &.icon-card-account:hover,
  &.icon-card-users:hover,
  &.icon-card-customers:hover,
  &.icon-card-calendar:hover,
  &.icon-card-absence:hover,
  &.icon-card-activities:hover,
  &.icon-card-menus:hover,
  &.icon-card-register:hover,
  &.icon-card-employees:hover,
  &.icon-card-website:hover,
  &.icon-card-webshop:hover,
  &.icon-card-online-payments:hover,
  &.icon-card-shop:hover,
  &.icon-card-marketing:hover {
    color: #ffffff;
  }
}

.card-icon-wrapper {
  position: relative;
  height: 80%;
  aspect-ratio: 1/1;
}

#cog-icon {
  opacity: 60%;
  width: 30% !important;
  height: 30%;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media only screen and (max-width: 600px) {
  .modal-content {
    width: 100% !important;
  }

  .modal-settings {
    padding: 0 !important;
  }
  .cards-settings-wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding: 0;
  }

  .card-icon {
    font-size: 4em;
    height: 80%;
    margin-left: 2%;
  }

  .icon-card {
    padding: 1.5em 0.75em 0.75em;
  }
  .card-text {
    font-size: 0.5em;
  }

  .modal {
    padding-top: 24px;
  }
}

@media only screen and (max-width: 1200px) {
  .card-text {
    font-size: 0.9em;
  }
}

.modal-modal-header-wrapper {
  display: flex;
  justify-content: space-between;
  -webkit-box-pack: justify;
}

.settings-modal-header {
  text-align: center;
  font-size: 1.75em;
  font-weight: 400;
  margin: 0 0 0.3em 0.8em;
  line-height: 1.2;
}

#settings-modal {
  display: none;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 85%;
  max-width: 700px;
  border-radius: 10px;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 479px) {
  .home-secondary-navigation a {
    font-size: 1em;
    display: block;
    margin: 0 0 1.2em;
  }
}

.home-secondary-navigation .icon {
  margin: 0 0.5em 0 0;
  font-size: 1.1em;
}

.home-branding {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.5);
  padding: 1em 0 0 0;
}

@media (max-width: 1023px) {
  .toolbar .calendar-span-week,
  .toolbar .calendar-zoom {
    display: none;
  }
}

@media (max-width: 767px) {
  .toolbar .calendar-span {
    display: none;
  }
}

.customers-list .customers-list-content {
  position: absolute;
  top: 2.7em;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 0 1.5em 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.customers-list.full .customers-list-content {
  top: 0;
}

.customers-list .divider {
  font-size: 1rem;
  margin: 0 1em 0.5em;
  padding: 0.5em 0 0.3em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.customers-counter {
  display: block;
  padding: 0.5em;
  color: #303640;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  font-size: 1rem;
}

.customers-list .customer {
  font-size: 1rem;
  display: block;
  color: #232934;
}

.customers-list .customer {
  display: block;
  padding: 0.4em 0 0.4em 2.5em;
  margin-right: 3em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (max-width: 1199px) {
  .customers-list .customers-list-content {
    padding: 0 0;
  }
  .customers-counter {
    padding-left: 1em;
  }
}

.customers-list .customer:hover {
  background: rgba(0, 0, 0, 0.05);
}

.customers-list .selected,
.customers-list .selected:hover {
  color: #fff;
  background: #2164db;
}

.customer-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.customer-layout .customer-sidebar {
  -webkit-flex-basis: 10em;
  -ms-flex-basis: 10em;
  flex-basis: 10em;
  text-align: center;
  margin-right: 3.5em;
  white-space: nowrap;
}

@media (max-width: 1299px) {
  .customer-layout .customer-sidebar {
    margin-right: 2em;
  }
}

@media (max-width: 599px) {
  .customer-layout {
    padding-left: 0;
  }
}

.customer-layout .avatar {
  width: 8em;
  height: 8em;
  border-radius: 50%;
  margin: 0 0 2em;
}

@media (max-width: 599px) {
  .customer-layout .avatar {
    width: 7em;
    height: 7em;
  }
}

.customer-layout .avatar-edit {
  position: relative;
  display: inline-block;
}

.customer-layout .avatar-edit .avatar {
  position: relative;
  display: block;
}

.customer-layout .avatar-edit span {
  position: absolute;
  left: 0;
  width: 8em;
  text-align: center;
  top: 0;
  display: none;
  line-height: 8em;
}

.customer-layout .avatar-edit:hover .avatar {
  opacity: 0.3;
}

.customer-layout .avatar-edit:hover span {
  display: inline-block;
}

.customer-layout .tab-list {
  text-align: left;
}

.customer-layout .tab-list a {
  display: block;
  padding: 0.6em 0.8em;
}

.customer-layout .tab-list .selected {
  background: rgba(33, 100, 219, 0.15);
  border-radius: 99px;
  box-sizing: content-box;
  width: 100%;
}

@media (max-width: 599px) {
  .customer-layout .tab-list .selected {
    border-radius: 0 99px 99px 0;
  }
}

.customer-layout .tab-list .icon {
  margin: 0 0.3em 0 0;
}

.customer-layout .delete {
  color: #d24444;
}

.customer-layout .warning {
  color: #fbb03b;
}

.customer-layout .status-message {
  &.warning,
  &.error,
  &.success,
  &.info {
    color: #ffffff;
  }
}

.customer-layout .delete.selected {
  background: rgba(210, 68, 68, 0.05);
}

.customer-layout .warning.selected {
  background: rgba(251, 176, 59, 0.05);
}

.customer-layout .customer-container {
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
}

.customer-layout
.customer-container
.customer-content
form
label.full
div.select {
  width: 100%;
}

.customer-layout
.customer-container
.customer-content
form
label.checkbox-row
input {
  float: left;
}

.customer-layout .customer-container .customer-content form label.checkbox-row {
  margin-bottom: 10px;
}

.customer-layout
.customer-container
.customer-content
form
label.checkbox-row
div.text {
  position: relative;
}

.customer-layout .name {
  font-size: 2em;
  font-weight: 300;
  margin: 0.5em 0 0.5em;
}

.customer-layout .product-list {
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.15);
}

.customer-layout .product-list .name {
  font-size: inherit;
}

.customer-layout .product-list .product {
  -webkit-flex-basis: 33.3333%;
  -ms-flex-basis: 33.3333%;
  flex-basis: 33.3333%;
}

@media (max-width: 1023px) {
  .customer-layout .product-list .product {
    -webkit-flex-basis: 50%;
    -ms-flex-basis: 50%;
    flex-basis: 50%;
  }
}

.customer-layout .customer-card .avatar {
  height: auto;
}

.customer-layout .customer-card .name {
  font-size: 1.125em;
}

.customer-search {
  margin: 0 0 1em;
  position: relative;
}

.customer-search > .icon {
  position: absolute;
  left: 0.7em;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.customer-search .customer-search-input,
.customer-search .customer-search-input:focus {
  outline: none;
  width: 100%;
  padding: 0.5em 0.5em 0.5em 2em;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
}

.customer-search .customer-search-results {
  display: none;
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 18em;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
}

.customer-search .customer-card {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  min-height: 3.4em;
}

.customer-search .customer-card:last-child {
  border: 0;
}

.customer-card {
  display: block;
  position: relative;
  padding: 0.6em 0.4em 0.6em 3.4em;
  min-height: 4.4em;
  color: #232934;
  text-align: left;
}

.customer-card:hover {
  background: #ecf0f6;
}

.customer-card .avatar {
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  width: 2.4em;
}

.customer-card .avatar img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  border-radius: 100%;
}

.customer-card .avatar.trusted:before {
  content: "";
  position: absolute;
  top: 1px;
  right: 1px;
  height: 8px;
  width: 8px;
  background: #fff;
}

.customer-card .avatar.trusted:after {
  content: "\70";
  position: absolute;
  top: -2px;
  right: -2px;
  font-family: "optios";
  font-size: 14px;
  color: #2164db;
}

.customer-card .name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 0 0.3em;
}

.customer-card .address,
.contact-phone {
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.3);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.product-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.product-list .product {
  -webkit-flex-basis: 20%;
  -ms-flex-basis: 20%;
  flex-basis: 20%;
  -webkit-box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.1);
}

@media (min-width: 1500px) {
  .product-list .product {
    -webkit-flex-basis: 16.6666%;
    -ms-flex-basis: 16.6666%;
    flex-basis: 16.6666%;
  }
}

@media (max-width: 1199px) {
  .product-list .product {
    -webkit-flex-basis: 25%;
    -ms-flex-basis: 25%;
    flex-basis: 25%;
  }
}

@media (max-width: 1023px) {
  .product-list .product {
    -webkit-flex-basis: 33.3333%;
    -ms-flex-basis: 33.3333%;
    flex-basis: 33.3333%;
  }
}

@media (max-width: 479px) {
  .product-list .product {
    -webkit-flex-basis: 50%;
    -ms-flex-basis: 50%;
    flex-basis: 50%;
  }
}

.product {
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  color: #232934;
  overflow: hidden;
  position: relative;

  .product-label {
    position: absolute;
    transform: rotate(-45deg);
    background-color: rgba($color-error, 1);
    color: white;
    top: 50px;
    left: -140px;
    padding: .2em 0;
    font-size: 65%;
    text-align: center;
    width: 400px;
    font-weight: bold;
  }
}

.product:hover {
  background: rgba(0, 0, 0, 0.03);
}

.product.error {
  box-shadow: none;
  border: 1px solid #d24444;
}

.product.warning {
  box-shadow: none;
  border: 1px solid #fbb03b;
}

.product-graphic {
  height: 8em;
  position: relative;
  margin-top: 1px;
}

.product-graphic .icon {
  font-size: 5em;
  color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.product-graphic img {
  display: block;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
}

.product-content {
  padding: 0.6em;
  line-height: 1.2;
}

.product .name {
  font-weight: 600;
  margin: 0 0 0.3em;
}

.product:hover .name {
  color: #2164db;
}

.product .brand {
  font-size: 0.8em;
  margin: 0 0 0.2em;
}

.product .category,
.product .last-sold {
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.5);
}

.product .price {
  position: absolute;
  right: 0.6em;
  top: 0.6em;
  background: rgba(0, 0, 0, 0.3);
  padding: 0.2em 0.4em;
  border-radius: 99px;
  color: #fff;
  line-height: 1;
  font-size: 0.7em;
}

.product .stock {
  position: absolute;
  left: 0.5em;
  top: 0.6em;
  background: rgba(255, 255, 255, 0.4);
  padding: 0.2em 0.3em;
  border-radius: 99px;
  color: rgba(0, 0, 0, 0.5);
  line-height: 1;
  font-size: 0.6em;
}

.product-content {
  .product-labels {
    text-align: center;

    .label {
      display: inline-block;
      padding: 0.2em 0.4em;
      border-radius: 99px;
      color: #fff;
      line-height: 1;
      font-size: 0.7em;
      margin-right: 0.25em;

      &:last-child {
        margin-right: 0;
      }

      &.success {
        background: $color-success;
      }

      &.warning {
        background: $color-warning;
      }

      &.info {
        background: $color-info;
      }

      &.muted {
        background: rgba(0, 0, 0, 0.3);
      }
    }
  }
}

.products-add-form .picture-upload-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.module-register.page-index .main-content {
  text-align: center;
}

.module-register.page-index .customer-search {
  display: inline-block;
}

@media (max-width: 1023px) {
  .module-register.page-index .customer-search {
    display: block;
    margin-top: 1em;
  }
}

.register-grid,
.register-grid2,
.register-grid3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -0.5em 0 0;
}

.register-grid .cell,
.register-grid3 .column {
  -webkit-flex-basis: 33.33%;
  -ms-flex-basis: 33.33%;
  flex-basis: 33.33%;
  overflow: hidden;
}

.register-grid2 .column {
  -webkit-flex-basis: 50%;
  -ms-flex-basis: 50%;
  flex-basis: 50%;
  overflow: hidden;
}

.register-grid3 .column,
.register-grid2 .column {
  padding-right: 1em;
  text-align: left;
  padding-bottom: 1px;
}

@media (max-width: 1023px) {
  .register-grid .cell,
  .register-grid2 .column,
  .register-grid3 .column {
    -webkit-flex-basis: 50%;
    -ms-flex-basis: 50%;
    flex-basis: 50%;
  }
}

@media (max-width: 479px) {
  .register-grid .cell,
  .register-grid2 .column,
  .register-grid3 .column {
    -webkit-flex-basis: 100%;
    -ms-flex-basis: 100%;
    flex-basis: 100%;
  }
  .register-grid .cell .customer-card,
  .register-grid2 .column .customer-card,
  .register-grid3 .column .customer-card {
    margin-bottom: -1px;
  }
}

.register-grid .customer-card,
.register-grid2 .customer-card,
.register-grid3 .customer-card {
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  margin: 0 0.5em 0.5em 0;
}

.checkout-customer {
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin: 0 0 2em;
  padding: 1em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.checkout-customer.error {
  border-color: #d24444;
}

.checkout-customer .error-text {
  color: #d24444;
}

.checkout-customer .avatar img {
  display: block;
  height: 4em;
  width: 4em;
  border-radius: 100%;
  margin: 0 1em 0 0;
}

.checkout-customer .details {
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
}

.checkout-customer .name {
  font-size: 1.2em;
  padding: 0 0 0.2em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #232934;
}

.checkout-customer .address,
.contact-phone {
  color: rgba(0, 0, 0, 0.5);
  padding: 0 0 0.5em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.checkout-customer .info {
  font-size: 80%;
  color: rgba(0, 0, 0, 0.5);
  margin: 0.5em 0;
}

.checkout-customer .actions a,
.checkout-customer .actions button {
  white-space: nowrap;
  text-align: right;
  display: inline-block;
  margin: 0 0 0.6em;
}

.checkout-customer .details .actions a {
  text-align: left;
}

.checkout-customer .totals {
  -webkit-flex: 0 1 50%;
  -ms-flex: 0 1 50%;
  flex: 0 1 50%;
  text-align: right;
}

.checkout-customer .total {
  font-weight: bold;
  margin: 0 0 0.6em;
  font-size: 1.4em;
}

.checkout-customer .resolution {
  margin: 0 0 1em;
  color: #69d321;
}

.checkout-customer .resolution .entry {
  margin: 0 0 0.5em;
}

.checkout-customer .resolution .entry.destructive {
  color: #d24444;
}

@media (max-width: 599px) {
  .checkout-customer {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    font-size: 0.8em;
  }
  .checkout-customer .details {
    -webkit-flex: 1 1 0;
    -ms-flex: 1 1 0;
    flex: 1 1 0;
  }
  .checkout-customers .info,
  .checkout-customer .actions,
  .checkout-customer .totals {
    -webkit-flex-basis: 100%;
    -ms-flex-basis: 100%;
    flex-basis: 100%;
  }
  .checkout-customer .totals {
    margin-top: 0.6em;
    text-align: left;
  }
  .checkout-customer .actions a {
    text-align: left;
    margin: 1em 0 0;
  }
}

.checkout-products-list {
  overflow: auto;
  border-bottom: 1px solid #666;
  border-top: 1px solid #666;
}

.checkout-products-list table {
  width: 100%;
  table-layout: fixed;
  empty-cells: show;
}

.checkout-products-list tr:nth-child(odd) {
  background: #ecf0f6;
}

.checkout-products-list tr.all {
  border-top: 1px solid #666;
}

.checkout-products-list td {
  padding: 0.6em;
}

@media (max-width: 479px) {
  .checkout-products-list .name {
    width: 10em;
  }
}

.checkout-products-list .price {
  width: 5em;
}

.checkout-products-list .price .currency {
  vertical-align: middle;
}

.checkout-products-list .price .input {
  width: 3em;
  margin: 0;
  text-align: center;
}

.checkout-products-list .edit-price .price,
.checkout-products-list .edit-price .discount,
.checkout-products-list .all .discount {
  cursor: pointer;
  color: #2164db;
}

.checkout-products-list .amount {
  width: 7em;
}

.checkout-products-list .amount .input {
  width: 2em;
  margin: 0 0.3em;
  text-align: center;
}

.checkout-products-list .amount .icon {
  vertical-align: middle;
  cursor: pointer;
}

.checkout-products-list .employee {
  width: 8em;
}

.checkout-products-list .discount {
  width: 5em;
  text-align: right;
}

.checkout-products-list .discount select {
  text-align: right;
  text-align-last: right;
}

.checkout-products-actions {
  padding-top: 1em;
  margin: 0 0 2em;
}

.checkout-products-actions .discount-all {
  float: right;
  margin: 0.5em 0 0;
}

.checkout-products-actions .discount-all .select {
  margin: 0 0.7em;
}

@media (max-width: 599px) {
  .checkout-products-actions .discount-all .label {
    display: none;
  }
}

.receipt-page .checkout-customer {
  border-bottom: 0;
  margin: 0;
  padding: 1em 1em 2em 1em;
}

.receipt-page .checkout-products {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-top: 0;
  margin-bottom: 2em;
}

.receipt-page .checkout-products-actions {
  padding: 1em;
  margin: 0;
}

.payment-totals {
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 1em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.payment-totals .extra {
  -webkit-flex: 0 1 50%;
  -ms-flex: 0 1 50%;
  flex: 0 1 50%;
}

.payment-totals .discount,
.payment-totals .tax {
  margin: 0 0 0.8em;
  color: rgba(0, 0, 0, 0.5);
}

.payment-totals .totals {
  -webkit-flex: 0 1 50%;
  -ms-flex: 0 1 50%;
  flex: 0 1 50%;
  text-align: right;
}

.payment-totals .total {
  font-weight: bold;
  margin: 0 0 0.6em;
  font-size: 1.4em;
}

.payment-totals .resolution {
  margin: 0 0 1em;
  color: #69d321;
}

.payment-totals .resolution .entry {
  margin: 0 0 0.5em;
}

.payment-totals .saldo {
  color: #d24444;
  font-weight: bold;
  font-size: 1.2em;
}

.payment-totals .paid {
  color: #69d321;
  font-weight: bold;
  font-size: 1.2em;
}

.payment-totals .ontab {
  margin-bottom: 1em;
}

.payment-totals .ontab .entry {
  font-size: 1.2em;
}

.payment-totals .ontab a {
  color: #2164db;
  font-size: 0.8em;
}

.payment-totals .ontab a.destructive {
  color: #d24444;
}

.payment-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.payment-options .payment-option {
  padding: 0.8em 0 1.2em;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-top: 0;
  -webkit-flex: 1 0 0;
  -ms-flex: 1 0 0;
  flex: 1 0 0;
}

.payment-options .payment-option:not(:last-child) {
  border-right-width: 0;
}

.payment-options .payment-option:hover {
  background: #eee;
}

.payment-options .payment-option img {
  width: 4em;
  display: block;
  margin: 0 auto 0.5em;
}

.payment-options .payment-option .label {
  text-align: center;
  white-space: nowrap;
  font-size: 0.9em;
}

.payment-options .payment-option .text {
  text-align: center;
  white-space: nowrap;
  font-size: 0.9em;
  color: #232934;
  margin-top: 0.5em;
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .payment-options {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .payment-options .payment-option {
    -webkit-flex: 0 0 16.667%;
    -ms-flex: 0 0 16.667%;
    flex: 0 0 16.667%;
  }
  .payment-options .payment-option:nth-child(3) {
    border-right-width: 1px;
  }
}

@media (max-width: 1023px) {
  .payment-options {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .payment-options .payment-option {
    -webkit-flex: 0 0 33.333%;
    -ms-flex: 0 0 33.333%;
    flex: 0 0 33.333%;
  }
  .payment-options .payment-option:nth-child(3) {
    border-right-width: 1px;
  }
}

.payment-cash {
  color: #40b56f;
}

.payment-debitcard {
  color: #005596;
}

.payment-creditcard {
  color: #fbb03b;
}

.payment-wiretransfer {
  color: #f13f24;
}

.payment-giftcard {
  color: #8262d8;
}

.payment-ontab {
  color: #666;
}

.payment-cheque {
  color: #009688;
}

.payment-dialog,
.giftcard-dialog {
  text-align: center;

  .graphic {
    width: 8em;
    display: block;
    margin: 0 auto 1em;
  }

  .dialog-actions {
    text-align: center;
    margin: 2em 0 0;
  }
}

.payment-amount-option {
  display: inline-block;
  position: relative;
  background: currentColor;
  margin: 0 0.5em 0.5em 0;
  border-radius: 5px;
  border: 2px solid;
  cursor: pointer;

  .radio {
    position: absolute;
    opacity: 0;

    &:checked + .label {
      background: none;
      color: #fff;
    }
  }

  .label {
    display: inline-block;
    background: #fff;
    font-size: 1.6em;
    padding: 0.6em;
    vertical-align: middle;
    border-radius: 3px;
  }

  &.dim,
  .dim {
    color: rgba(0, 0, 0, 0.3);
  }

  .error {
    color: white;
    position: absolute;
    background-color: $color-danger;
    padding: 0.5em;
    border-radius: 5px;
    width: auto;
    bottom: 4em;
  }

  &.custom {
    .label {
      padding: 0.22em 0.6em;
    }

    .input {
      width: 3em;
      color: inherit;
    }

    span {
      vertical-align: middle;
    }
  }
}

.breadcrumb-list {
  margin-bottom: 1em;
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 1em 1em;
  color: rgba(black, 0.5);

  li {
    margin-bottom: 0.5em;

    span {
      white-space: nowrap;
    }
  }
}

.textwrapper {
  border: 1px solid transparent;
  margin: 5px 0;
  padding: 3px;
  min-height: 8em;
  margin-bottom: 1em;
}

.hidden {
  display: none !important;
}

.remark-card {
  display: block;
  position: relative;
  padding: 0.6em 0.4em 0.6em 0.4em;
  min-height: 3.3em;
  color: #232934;
  text-align: left;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 0.5em;
}

.remark-card .text {
  overflow: hidden;
  margin: 0 0 0.3em;
  padding-right: 4em;
}

.remark-card .date {
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.3);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}

.table tbody > tr.even {
  background-color: #ffffff !important;
}

.table.table-minimal th,
.table.table-minimal td {
  padding: 0.25rem;
}

.table th,
.table td {
  vertical-align: bottom;
  padding: 0.75rem;
  border-top: 1px solid #ececec;
}

.table.table-valign-center th,
.table.table-valign-center td {
  vertical-align: middle;
}

.table.table-valign-top th,
.table.table-valign-top td {
  vertical-align: top;
}

.table thead th {
  border-bottom: 2px solid #eceeef;
  text-align: left;
}

.table thead th.text-right {
  text-align: right;
}

.table thead th.text-center {
  text-align: center;
}

.table:not(.not-striped) tbody > tr:nth-of-type(odd),
.table tbody > tr.odd {
  background-color: #ecf0f6;
}

.table .separator th,
.table .separator td,
.table .seperator th,
.table .seperator td {
  border-bottom: 1px solid #333;
}

.table .separator-top th,
.table .separator-top td,
.table .seperator-top th,
.table .seperator-top td {
  border-top: 1px solid #333;
}

.table .separator-light th,
.table .separator-light td,
.table .seperator-light th,
.table .seperator-light td {
  border-bottom: 1px solid #999;
}

.table .separator-light-top th,
.table .separator-light-top td,
.table .seperator-light-top th,
.table .seperator-light-top td {
  border-top: 1px solid #999;
}

.table td.separator-left,
.table th.separator-left,
.table td.separator-left,
.table th.separator-left {
  border-left: 1px solid #333;
}

.table td.separator-light-left,
.table th.separator-light-left,
.table td.separator-light-left,
.table th.separator-light-left {
  border-left: 1px solid #999999;
}

.table td.separator-right,
.table th.separator-right,
.table td.separator-right,
.table th.separator-right {
  border-right: 1px solid #333;
}

.table td.separator-light-right,
.table th.separator-light-right,
.table td.separator-light-right,
.table th.separator-light-right {
  border-right: 1px solid #999999;
}

.table-footer {
  font-size: 0.7em;
  margin-bottom: 1.2rem;
}

.table + .table-footer {
  margin-top: -0.9rem;
}

a.destructive,
button.destructive {
  color: $color-error;
}

a.warning,
button.warning {
  color: $color-warning;
}

small,
sup,
sub {
  font-size: 0.7em;
  opacity: 0.7;
}

sup {
  vertical-align: super;
}

sub {
  vertical-align: sub;
}

.dropdown-icons {
  line-height: 2em;
}

.dropdown-icons .icon {
  padding: 0.3em 0.4em;
  border-radius: 3px;
  cursor: pointer;
}

.dropdown-icons .icon.selected {
  background-color: #2164db;
  color: white;
}

.line {
  margin: 1em 0em 1em 0em;
  border: 1px solid #ccc;
}

button.no-style,
button.a-style,
button.href-style {
  background-color: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  height: auto;
  display: inline;
  inline-size: auto;
  perspective-origin: 0px 0px;
  text-align: left;
  transform-origin: 0px 0px;
  vertical-align: baseline;
}

button.href-style {
  color: #2164db;
}

button.a-style {
  color: #2164db;
  background-color: #dee1e7;
  border-radius: 29px;
  padding: 8px 10px;
  margin-left: 5px;
}

button.a-style.destructive {
  color: #d24444;
}

button.a-style.disabled {
  color: #777777 !important;
  border-color: #777777 !important;
  cursor: default;
}

.register-check-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.register-check-options .register-check-option {
  padding: 0.8em 0 1.2em;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-top: 0;
  -webkit-flex: 1 0 0;
  -ms-flex: 1 0 0;
  flex: 1 0 0;
}

.register-check-options .register-check-option:not(:last-child) {
  border-right-width: 0;
}

.register-check-options .register-check-option img {
  width: 4em;
  display: block;
  margin: 0 auto 0.5em;
}

.register-check-options .register-check-option .label {
  flex-grow: 1;
  text-align: center;
}

.register-check-options .register-check-option .text {
  text-align: center;
  white-space: nowrap;
  font-size: 0.9em;
  color: #232934;
  margin-top: 0.5em;
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .register-check-options {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .register-check-options .register-check-option {
    -webkit-flex: 0 0 16.667%;
    -ms-flex: 0 0 16.667%;
    flex: 0 0 16.667%;
  }
  .register-check-options .register-check-option:nth-child(3) {
    border-right-width: 1px;
  }
}

@media (max-width: 1023px) {
  .register-check-options {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .register-check-options .register-check-option {
    -webkit-flex: 0 0 33.333%;
    -ms-flex: 0 0 33.333%;
    flex: 0 0 33.333%;
  }
  .register-check-options .register-check-option:nth-child(3) {
    border-right-width: 1px;
  }
}

.register-check-bills {
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.register-check-bills .register-check-bill {
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  -webkit-flex: 0 0 20%;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  text-align: center;
}

.register-check-bills .register-check-bill img {
  width: 4em;
  height: 4em;
}

.register-check-bills .register-check-bill input {
  width: 90%;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 0 0.5em;
  margin-bottom: 0.5em;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.form-image-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
}

.form-image-grid .form-image {
  -webkit-flex-basis: 20%;
  -ms-flex-basis: 20%;
  flex-basis: 20%;
  -webkit-box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  padding: 0.5em;
}

.form-image-grid .form-image.active {
  background-color: #2164db;
}

.form-image-grid .form-image img {
  width: 100%;
}

@media (max-width: 1199px) {
  .form-image-grid .form-image {
    -webkit-flex-basis: 25%;
    -ms-flex-basis: 25%;
    flex-basis: 25%;
  }
}

@media (max-width: 1023px) {
  .form-image-grid .form-image {
    -webkit-flex-basis: 33.3333%;
    -ms-flex-basis: 33.3333%;
    flex-basis: 33.3333%;
  }
}

@media (max-width: 479px) {
  .form-image-grid .form-image {
    -webkit-flex-basis: 50%;
    -ms-flex-basis: 50%;
    flex-basis: 50%;
  }
}

.form-icon {
  float: left;
  margin-right: 1.5em;
  margin-top: 0.5em;
  opacity: 0.3;
  curor: pointer;
}

.form-icon.active {
  opacity: 1;
}

.form-icon .icon-fa.major {
  font-size: 0.5em;
}

.form-icon .icon-fa.major:before {
  font-size: 18px;
}

.form-color-grid {
  display: flex;
  flex-wrap: wrap;
}

.form-color {
  width: 1.6em;
  height: 1.6em;
  float: left;
  margin: 0.4em;
  cursor: pointer;
  border: 1px solid #babdbe;

  &.active {
    width: 2.4em;
    height: 2.4em;
    margin: 0;
    box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.5);
  }
}

.activity-search {
  margin: 0 0 1em;
  position: relative;
}

.activity-search > .icon {
  position: absolute;
  left: 0.7em;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.activity-search .activity-search-input {
  width: 100%;
  padding: 0.5em 0.5em 0.5em 2em;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
}

.activity-search .activity-search-input:focus {
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
}

.activity-search .activity-search-results {
  display: none;
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 18em;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
}

.activity-search .activity-card {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  min-height: 3.4em;
}

.activity-search .activity-card:last-child {
  border: 0;
}

.activity-card-list {
}

.activity-card-list .activity-card {
  border-bottom: 1px solid #e5e5e5;
  min-height: 3.4em;
}

.activity-list-total {
  font-size: 0.8em;
  margin: 0.5em 0 0 4.875em;
}

/*garphicstyle="position: absolute; left: 0; top: 0; height: 3.75em; width: 3.75em;"*/
.activity-card {
  display: block;
  position: relative;
  padding: 0.6em 0.4em 0.6em 3.9em;
  min-height: 3.5em;
  color: #232934;
  text-align: left;
}

.activity-card:hover {
  background: #ecf0f6;
}

.activity-card .avatar {
  position: absolute;
  top: 0;
  left: 0;
  width: 3.4em;
  height: auto;
  margin: 0;
  border-radius: 0;
}

.activity-card .avatar img {
  display: block;
  width: 100%;
  height: 100%;
}

.activity-card .avatar.trusted:before {
  content: "";
  position: absolute;
  top: 1px;
  right: 1px;
  height: 8px;
  width: 8px;
  background: #fff;
}

.activity-card .avatar.trusted:after {
  content: "\70";
  position: absolute;
  top: -2px;
  right: -2px;
  font-family: "optios";
  font-size: 14px;
  color: #2164db;
}

.activity-card .name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 0 0.3em;
  font-size: inherit;
}

.activity-card .address,
.contact-phone {
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.3);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.contact-phone-customers {
  font-size: .8em;
  color: rgba(0, 0, 0, .3);
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  padding: 0.4em 0 0.4em 0;
  display: block;
}

.link-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0.4em 0 0.4em 2.5em;
  margin-right: 3em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link-span {
  position: relative;
  z-index: 1;
}

.highlight {
  position: relative;
  z-index: 0;
  display: inline;
  background-color: yellow;
}

.activity-card .actions {
  position: absolute;
  right: 0.5em;
  top: 1.2em;
}

.user-grid {
  margin-bottom: 1em;
}

.user-grid .customer-card {
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 0.5em;
  max-width: 500px;
}

.user-grid .customer-card .actions {
  position: absolute;
  right: 1em;
  top: 1em;
}

.blocked-category-card {
  position: relative;
  min-height: 3em;
  padding: 0.5em;
  padding-left: 3.5em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.blocked-category-card .graphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 3em;
  height: 3em;
}

.blocked-category-card .info {
  flex-grow: 1;
}

.blocked-category-card .category-name {
  display: block;
}

.blocked-category-card .duration {
  font-size: 80%;
  color: rgba(0, 0, 0, 0.3);
}

.blocked-category-list {
  margin-bottom: 1em;
}

.daterange-card {
  display: flex;
  margin-bottom: 1em;
}

.daterange-card .from {
  margin-right: 1em;
}

.daterange-card .actions {
  margin-left: 1em;
  padding-top: 0.8em;
}

.daterange-card .label {
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.3);
}

.appointment-card {
  display: block;
  position: relative;
  padding: 0.6em 0.4em 0.6em 0.4em;
  min-height: 3.3em;
  color: #232934;
  text-align: left;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 0.5em;
}

.appointment-card .appointment-card-date {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 0 0.3em;
}

.appointment-card .appointment-card-description {
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.3);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.appointment-card .appointment-card-actions {
  margin-top: 0.3em;
  font-size: 0.8em;
}

.or-divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 1em;
}

.or-divider span {
  background-color: white;
  padding: 0 0.5em;
  position: relative;
  top: 0.5em;
  left: 45%;
}

.form-avatar-image-grid .form-avatar-image {
  display: inline-block;
  opacity: 0.3;
  margin-right: 1em;
}

.form-avatar-image-grid .form-avatar-image.active {
  opacity: 1;
}

.form-avatar-image-grid .form-avatar-image img {
  border-radius: 100%;
}

.register-card {
  display: block;
  position: relative;
  padding: 0.6em 0.4em 0.6em 0.4em;
  min-height: 3.3em;
  color: #232934;
  text-align: left;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 0.5em;
  max-width: 30em;
}

.register-card .label {
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.3);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.register-card .text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 0 0.3em;
}

.register-card .actions {
  position: absolute;
  right: 0.5em;
  top: 1.4em;
}

.payment-type-input {
  max-width: 30em;
}

.payment-type-input img {
  width: 4em;
  height: 4em;
  padding: 0.5em;
}

.payment-type-input .text {
  vertical-align: middle;
  padding: 0.5em 0.5em 0.5em 1em;
  line-height: 3em;
  flex-grow: 1;
}

.payment-type-input input ~ img {
  border-left: 0.1em solid #cccccc;
  border-top: 0.1em solid #cccccc;
  border-bottom: 0.1em solid #cccccc;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.payment-type-input input:checked ~ img {
  border-left: 0.1em solid #2164db;
  border-top: 0.1em solid #2164db;
  border-bottom: 0.1em solid #2164db;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.payment-type-input input:checked ~ .text {
  border-right: 0.1em solid #2164db;
  border-top: 0.1em solid #2164db;
  border-bottom: 0.1em solid #2164db;
  line-height: 2.8em;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.payment-type-input input ~ .text {
  border-right: 0.1em solid #cccccc;
  border-top: 0.1em solid #cccccc;
  border-bottom: 0.1em solid #cccccc;
  line-height: 2.8em;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.tab-card {
  display: block;
  position: relative;
  padding: 0.6em 0.4em 0.6em 3.4em;
  min-height: 4.4em;
  color: #232934;
  text-align: left;
}

.tab-card:hover {
  background: #ecf0f6;
}

.tab-card .avatar {
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  width: 2.4em;
}

.tab-card .avatar img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  border-radius: 100%;
}

.tab-card .text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 0 0.3em;
}

.tab-card .amount {
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.3);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tab-card a {
  font-size: 0.8em;
}

.chart-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.report-container-chart {
  text-align: center;
}

.report-container-chart canvas {
  max-width: 300px;
  display: inline-block;
}

.report-container-data,
.report-container-chart {
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
}

@media (max-width: 610px) {
  .report-container {
    display: block;
  }
}

.brand-card {
  display: block;
  position: relative;
  padding: 0.6em 0.4em 0.6em 0.4em;
  min-height: 3.3em;
  color: #232934;
  text-align: left;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 0.5em;
  max-width: 30em;
  font-size: 16px;
}

.brand-card .info {
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.3);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.brand-card .name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 0 0.3em;
  font-size: 1.2em;
}

.brand-card .actions {
  position: absolute;
  right: 0.5em;
  top: 1.4em;
}

td > .input {
  width: 3em;
  margin: 0 0.3em;
  text-align: center;
}

td > .input.text-right {
  text-align: right;
}

td > .input.text-left {
  text-align: left;
}

td .select {
  display: block;
}

.horizonal-product {
  display: flex;
}

.horizonal-product .graphic {
  width: 5em;
  margin-right: 1em;
}

.horizonal-product .graphic img {
  width: 100%;
}

.horizonal-product .info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.horizonal-product .name {
  font-weight: 600;
}

.horizonal-product .brand {
  font-size: 0.8em;
}

.horizonal-product .category {
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.5);
}

dl dt {
  color: rgba(0, 0, 0, 0.5);
}

dl dd {
  margin-bottom: 0.7em;
}

.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table !important;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table !important;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table !important;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table !important;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table !important;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}

.visible-print-block {
  display: none !important;
}

@media print {
  .visible-print-block {
    display: block !important;
  }
}

.visible-print-inline {
  display: none !important;
}

@media print {
  .visible-print-inline {
    display: inline !important;
  }
}

.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .hidden-print {
    display: none !important;
  }
}

.quill {
  width: 100%;
  margin-bottom: 48px;

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }
}

.checkbox-button-icons.button {
  display: inline-block;
}

.checkbox-button-icons input {
  display: none;
}

.checkbox-button-icons input:checked ~ .checked {
  display: block;
}

.checkbox-button-icons input:checked ~ :not(.checked) {
  display: none;
}

.checkbox-button-icons input:not(:checked) ~ .checked {
  display: none;
}

.checkbox-button-icons input:not(:checked) ~ :not(.checked) {
  display: block;
}

.list-ul {
  list-style: disc;
  margin-left: 1em;
}

.list-ul li {
  margin-bottom: 1em;
}

.loyaltycard-card {
  display: block;
  position: relative;
  padding: 0.6em 0.4em 0.6em 0.4em;
  min-height: 3.3em;
  color: #232934;
  text-align: left;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 0.5em;
  max-width: 30em;
  font-size: 16px;
}

.loyaltycard-card.furfilled {
  border-color: #69d321;
  -webkit-box-shadow: inset 0 0 0 1px rgba(105, 211, 33, 0.5);
  box-shadow: inset 0 0 0 1px rgba(105, 211, 33, 0.5);
}

.loyaltycard-card.expired {
  border-color: #d24444;
  -webkit-box-shadow: inset 0 0 0 1px rgba(210, 68, 68, 0.5);
  box-shadow: inset 0 0 0 1px rgba(210, 68, 68, 0.5);
}

.loyaltycard-card.open {
  border-color: #00a2c2;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 162, 194, 0.5);
  box-shadow: inset 0 0 0 1px rgba(0, 162, 194, 0.5);
}

.loyaltycard-card .info {
  font-size: 0.8em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.loyaltycard-card .name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 1.2em;
  padding: 0.75rem;
}

.loyaltycard-card .actions {
  position: absolute;
  right: 0.5em;
  top: 1.3em;
}

.loyaltycard-card .table tbody tr:nth-of-type(odd) {
  background: none;
}

.loyaltycard-card .table tbody tr:first-child {
  td,
  th {
    border: 0;
  }
}

.punchclock-card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: stretch;
}

.punchclock-card {
  text-align: center;
  width: 12em;
  display: inline-block;
  margin-right: 1em;
  margin-bottom: 1em;
}

.punchclock-card .avatar {
  width: 8em;
  height: 8em;
  border-radius: 50%;
  margin-bottom: 1em;
}

.punchclock-card .name {
  text-wrap: none;
  text-overflow: ellipsis;
}

.punchclock-card .info {
  margin: 0.3em 0;
}

.punchclock-card .info .label {
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.3);
}

.punchclock-card .info .text {
}

.table-punchclock .avatar {
  width: 2em;
  height: 2em;
  border-radius: 50%;
}

.table-punchclock .time-label {
  min-width: 4.5em;
  display: inline-block;
}

.subscription-card {
  display: block;
  position: relative;
  padding: 0.6em 0.4em 0.6em 0.4em;
  min-height: 3.3em;
  color: #232934;
  text-align: left;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 0.5em;
  max-width: 30em;
  font-size: 16px;
}

.subscription-card .info {
  font-size: 0.8em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 0.75rem;
}

.subscription-card .name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 1.2em;
  padding: 0.75rem;
}

.subscription-card .table {
  margin: 0.75em 0;
}

.subscription-card .table td:first-child {
  padding: 0 0.75em;
}

.wizard li {
  background-color: rgba(0, 0, 0, 0.5);
  width: 2em;
  height: 2em;
  border-radius: 50%;
  float: left;
  margin: 0 2em;
  text-align: center;
  line-height: 2em;
  position: relative;
  color: white;
  -webkit-box-shadow: 3px 3px 3px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 3px 3px 3px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 3px 3px 3px 1px rgba(0, 0, 0, 0.2);
}

.wizard li > span {
  position: absolute;
  top: 2.5em;
  width: 7em;
  color: darkgray;
  left: -2.5em;
  color: rgba(0, 0, 0, 0.5);
}

.wizard li.completed {
  background-color: #69d321;
}

.wizard li.active {
  background-color: #2164db;
}

@media (max-width: 599px) {
  .wizard {
    display: none;
  }
}

.activity-card-list {
  position: relative;
}

.printed-receipt-wrapper {
  display: flex;

  > div {
    flex-grow: 1;
    flex-basis: 0;

    &:first-child {
      margin-right: 1em;
    }
  }

  #receipt_example {
    font-family: "Courier New", Courier, monospace;
    white-space: pre;
  }
}

@media (max-width: 1320px) {
  .printed-receipt-wrapper {
    flex-direction: column;
  }
}

.form-split {
  label.info {
    color: #fff;
    padding: 0.3em 0.4em;
    font-size: 0.9em;
    position: absolute;
    border-radius: 3px;
    right: 0;
    top: 0;
    background-color: #2164db;
  }
}

.customer-form {
  label.info {
    right: -1em;
    top: -2em;
  }
}

.survey-submission-detail > .answer {
  label {
    font-weight: bold;
  }
}

.customer-detail-icon-list {
  color: #2164db;
}

.title-with-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title-buttons {
    display: flex;
    margin-left: 0.8em;
    font-size: 0.7em;
  }
}

.facebook {
  margin: 1em;
}

table.person-activity-overview-table {
  td b {
    font-weight: 600;
  }
  th {
    vertical-align: top;
    font-weight: 500;

    b {
      font-weight: 600;
    }

    .online-bookable-header {
      margin-bottom: 8px;

      .icon::before {
        line-height: 0.7;
      }
    }
  }

  th:nth-child(1),
  td:nth-child(1) {
    text-align: center;
  }
}

.blocked-box {
  .add-blocked-category-btn {
    padding-top: 0.3em;
  }

  .add-blocked-category-actions {
    text-align: right;

    .button.small {
      margin-top: 0;
      margin-bottom: 1em;
    }
  }
}

.workinghours-warning {
  color: white;
  background-color: #2164db;
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-radius: 1em;
  padding: 1em;
  margin-bottom: 1em;
}

.workinghours-warning > * {
  display: block;
  margin: 1em 1em 1em 1em;
}

.workinghours-warning p:first-child {
  svg {
    width: 100%;
    height: 100%;
    max-height: 3em;
    max-width: 23em;
  }
}

@media (min-width: 769px) {
  #settingsSideMenu {
    display: none;
  }
}

.cash-state-text-indent-small {
  text-indent: 2em;
}

.free-moments-score-wrapper {
  margin-bottom: 2em;

  .free-moment-score-container {
    display: flex;
    flex-wrap: wrap;
  }
}

.receipt-opt-in {
  text-align: center;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
}

.bg-warning-light {
  background-color: $color-warning;
  color: #fff;
}

.receipt-opt-in .button {
  color: #fff;
}

.receipt-opt-in .button:not([disabled]):hover,
.receipt-opt-in .button:focus,
.receipt-opt-in .button:active {
  background: $color-warning;
  border-color: #d68404;
}

.container-page_filters {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;

  .container-filters {
    border: 1px solid #d3d3d3;
    padding: 0.5em;
    margin-bottom: 0.5em;
    width: 100%;

    form {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }
}

table.customer-tags-table {
  td.customer-tag-customer-count {
    padding-bottom: 1em;
  }
}

.customer-tag {
  font-weight: 600;
  padding: 0.5em 0.8em;
  border-radius: 15px;
  width: fit-content;
  box-shadow: 2px 2px 2px #d3d3d3;
  font-size: 0.9em;

  a.remove-customer-tag {
    position: absolute;
    top: -2px;
    background-color: #d3d3d3;
    border-radius: 50%;
    width: 14px;
    height: 13px;
    text-align: center;

    &:hover {
      background-color: red;
      color: white;
    }

    i {
      font-size: 0.5em;
      vertical-align: top;
    }
  }
}

.customer-tags-wrapper {
  display: inline-block;
  margin-bottom: 1.5em;

  .customer-tags {
    float: left;

    .customer-tag {
      position: relative;
      float: left;
      margin: 0 0.5em 0.5em 0;
    }
  }

  .add-tag {
    float: left;
    color: #2164db;
    padding-top: 0.5em;
    margin-left: 0.5em;

    a:hover {
      font-weight: 600;
    }

    i {
      font-size: 0.7em;
      margin-right: 0.3em;
    }
  }
}

.tag-color-options-wrapper {
  margin: 2em 0;

  .tag-color-option {
    width: 1.6em;
    height: 1.6em;
    float: left;
    margin: 0.4em;
    cursor: pointer;
    border: 1px solid #babdbe;

    &.active {
      width: 2.4em;
      height: 2.4em;
      margin: 0;
      box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, .5);
    }
  }
}

.remark-images-wrapper {
  float: left;

  .remark-image {
    position: relative;
    margin-right: 10px;
    float: left;

    a.remove-remark {
      position: absolute;
      top: -6px;
      left: 93px;
      background-color: #d3d3d3;
      border-radius: 50%;
      width: 14px;
      height: 13px;
      text-align: center;

      &:hover {
        background-color: red;
        color: white;
      }

      i {
        font-size: 0.5em;
        vertical-align: top;
      }
    }
  }
}

.toggle-all-categories {
  margin-left: auto;
  padding-left: 10px;
}

.freeze-column {
  display: flex;
  position: sticky;
  left: 0;
  z-index: 1;
}

.table tr:nth-of-type(odd) .freeze-column {
  background-color: #ecf0f6;
}

.table tbody > tr:nth-of-type(even) .freeze-column {
  background-color: #fff;
}

.scroll-table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.checkbox-label {
  display: flex;
  white-space: nowrap;
  min-height: auto !important;
  max-width: 10vw !important;
  overflow: hidden;
}
