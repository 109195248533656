@each $name, $color in $colors {
  .background-#{$name} {
    background-color: $color;
  }

  .text-#{$name} {
    color: $color;
  }
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-italic {
  font-style: italic;
}

.img-round {
  border-radius: 50%;
}

.pull-right {
  float: right;
}

.clickable {
  cursor: pointer;
}

.nowrap {
  white-space: nowrap;
}

.w-50 {
  width: 50%;
}

@each $direction in $directions {
  @each $sizeName, $sizeValue in $sizes {
    .margin-#{$direction}-#{$sizeName} {
      margin-#{$direction}: #{$sizeValue} !important;
    }
    .padding-#{$direction}-#{$sizeName} {
      padding-#{$direction}: #{$sizeValue} !important;
    }
  }
}
