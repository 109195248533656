
/* DATEPICKER */
.datepicker {
  text-align: center;
  margin: 0 0 1em;
  width: 620px;
}
.datepicker-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin: 0 0 1em;
}
.datepicker-header .datepicker-title {
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
}
.datepicker-header select {
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: 0;
  color: $color-primary;
}
.datepicker-header .datepicker-prev,
.datepicker-header .datepicker-next {
  -webkit-flex-basis: 12.5%;
  -ms-flex-basis: 12.5%;
  flex-basis: 12.5%;
}
.datepicker-days {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin: 0 0 0.5em;
}
.datepicker-days .day,
.datepicker-days .week {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.datepicker-calendar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.datepicker-calendar .week {
  font-style: italic;
  color: rgba(0,0,0,0.3);
  font-size: 90%;
  padding-top: .8em
}

.datepicker-calendar .day {
  color: #232934;
}

.datepicker-calendar .day,
.datepicker-calendar .week {
  -webkit-flex-basis: 12.5%;
  -ms-flex-basis: 12.5%;
  flex-basis: 12.5%;
  padding: 0.6em 0;
  position: relative;
}
.datepicker-calendar .day:before,
.datepicker-calendar .week:before {
  content: "";
  position: absolute;
  z-index: -1;
  width: 1.8em;
  height: 1.8em;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 100%;
}
.datepicker-calendar .day:hover:before {
  background-color: rgba(0,0,0,0.1);
}
.datepicker-calendar .closed {
  color: rgba(0,0,0,0.5);
}
.datepicker-calendar .other-month {
  color: rgba(0,0,0,0.15);
}
.datepicker-calendar .today:before {
  background-color: $color-warning;
}
.datepicker-calendar .selected {
  color: #fff;
}
.datepicker-calendar .selected:hover:before,
.datepicker-calendar .selected:before,
.datepicker-calendar .today.selected:hover:before,
.datepicker-calendar .today.selected:before {
  background-color: $color-primary;
}

.datepicker-calendar .disabled {
  color: rgba(0,0,0,0.15);
  cursor: default;
}
.datepicker-calendar .disabled:hover:before,
.datepicker-calendar .disabled:before {
  background-color: transparent;
}

@media (max-width: 620px) {
  .datepicker {
    width: auto;
  }
}
